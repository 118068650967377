import React from 'react';
import {InputBaseProps} from '@mui/material/InputBase';
import {TextFieldProps, Tooltip} from '@mui/material';
import {TestAttributes} from '../../TestAttributes';
import {OnEventHandler} from '../model';

export function buildSetValue({onChange, name}: SpartanFieldProps) {
  return (value: any) => {
    onChange({target: {name, value}});
  };
}

export function wrapInTooltipIfNecessary(props: SpartanFieldProps, element: JSX.Element) {
  return props.tooltip ? <Tooltip title={props.tooltip}>{element}</Tooltip> : element;
}

export interface SpartanFieldProps {
  onChange: OnEventHandler;
  onBlur: OnEventHandler;
  onFocus: OnEventHandler;
  id: any;
  label: string;
  placeholder?: string;
  name: string;
  value: any;
  disabled?: boolean;
  hasError?: boolean;
  error?: string;
  tooltip?: string | JSX.Element;
  isNestedLabel?: boolean;
}

export interface SpartanInputFieldProps extends SpartanFieldProps {
  // Attributes applied to the input element.
  inputProps?: InputBaseProps['inputProps'];
  inputRef?: React.Ref<HTMLInputElement>;
}

export interface SpartanTextFieldProps extends SpartanInputFieldProps {
  // See https://material-ui.com/api/text-field/#props
  // Props applied to the Input element. It will be a FilledInput, OutlinedInput or Input component depending on the variant prop value.
  InputProps?: TextFieldProps['InputProps'];
}

export interface SpartanFieldWithOptionProps extends SpartanInputFieldProps {
  options: SpartanFieldOption[];
}

export interface SpartanFieldOption {
  value: string; // FIXME: change this with `string | number | boolean`
  label: string;
  selectable?: boolean;
  is_required?: boolean;
  field_id?: string;
}

export function inputPropsWithTestAttributes(props: SpartanInputFieldProps): InputBaseProps['inputProps'] {
  const {id, name, inputProps} = props;
  // IMPORTANT overwrite test attributes only if attribute value is defined
  return {...inputProps, ...(id && {[TestAttributes.FIELD_ID]: id}), ...(name && {[TestAttributes.FIELD_NAME]: name})};
}

export function propsWithTestAttributes<T extends SpartanInputFieldProps>(props: T): T {
  const inputProps = inputPropsWithTestAttributes(props);
  return {...props, inputProps};
}
