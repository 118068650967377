import {useCallback, useState} from 'react';
import {Grid, Typography, useMediaQuery, useTheme} from '@mui/material';
import {FormFieldSchema, FormFieldType} from './logic/FormSchema';
import {SpartanNoteField} from './fields/SpartanNoteField';
import {SpartanTextField} from './fields/SpartanTextField';
import {SpartanPhoneField} from './fields/SpartanPhoneField';
import {SpartanExtensionField} from './fields/SpartanExtensionField';
import {SpartanZipCodeField} from './fields/SpartanZipCodeField';
import {SpartanEmailField} from './fields/SpartanEmailField';
import {SpartanPasswordField} from './fields/SpartanPasswordField';
import {SpartanNumberField} from './fields/SpartanNumberField';
import {SpartanTextAreaField} from './fields/SpartanTextAreaField';
import {SpartanSelectField} from './fields/SpartanSelectField';
import {SpartanMultiSelectField} from './fields/SpartanMultiSelectField';
import {SpartanCurrencyField} from './fields/SpartanCurrencyField';
import {SpartanCheckboxField} from './fields/SpartanCheckboxField';
import {SpartanCheckboxesField} from './fields/SpartanCheckboxesField';
import {SpartanRadioGroupField} from './fields/SpartanRadioGroupField';
import {SpartanDateField} from './fields/SpartanDateField';
import {SpartanTimeField} from './fields/SpartanTimeField';
import {SpartanDateTimeField} from './fields/SpartanDateTimeField';
import {SpartanAutoAddressField} from './fields/SpartanAutoAddressField';
import {SpartanAutoFillOptionCardField} from './fields/SpartanAutoFillOptionCardField';
import {SpartanSubmitTextAreaField} from './fields/SpartanSubmitTextAreaField';
import {SpartanCreditField} from './fields/SpartanCreditField';
import {SpartanSocialField} from './fields/SpartanSocialField';
import {SpartanCvvField} from './fields/SpartanCvvField';
import {SpartanLabelField} from './fields/SpartanLabelField';
import {ErrorMessage} from './fields/components/ErrorMessage';
import {decorateOnChange, toSize} from './utils';
import RenderCount from './components/RenderCount';
import {SpartanFieldOption, SpartanFieldProps} from './fields/model';
import {extractFieldState, FromFieldState, StringKeyObject} from './state/FormState';
import {SpartanCommunicationField} from './fields/SpartanCommunicationField';
import {SpartanHistoryField} from './fields/SpartanHistoryField';
import {SpartanINSXField} from './fields/SpartanINSXField';
import {SpartanCommentField} from './fields/SpartanCommentField';
import {SpartanSearchField} from './fields/SpartanSearchField';
import {Lead} from '../model/Lead';
import {SpartanRichTextField} from './fields/SpartanRichTextField';
import {SpartanFileUploader} from './fields/SpartanFileUploader';
import {WithFormDrillDownProps} from './model';
import {evalExpression} from './logic/eval/Eval';
import {fromControlledComponentValue, toControlledComponentValue} from './logic/utils';
import {SpartanTBCField} from './fields/SpartanTBCField';
import {useTranslation} from 'react-i18next';
import {useFormHelper} from './FormHelper';
import {SpartanLinkField} from './fields/SpartanLinkField';
import Auth0ContextHolder from '../services/Auth0ContextHolder';
import {getOrgName} from '../model/Auth0User';
import {SpartanPhoneFavtelField} from './fields/SpartanPhoneFavtelField';
import {SpartanDateTimeWithTzField} from './fields/SpartanDateTimeWithTzField';

function resolveLabelTemplate(label_template: string | undefined, values: StringKeyObject<any>) {
  if (label_template) {
    try {
      return evalExpression(label_template, values);
    } catch (e) {
      console.warn(`Invalid label_template`, label_template, e);
    }
  }
  return undefined;
}

export interface FormFieldProps extends WithFormDrillDownProps {
  field: FormFieldSchema;
}

export function FormField(props: FormFieldProps) {
  const {field, drillDownProps} = props;
  const {state} = drillDownProps;
  const {field_name: name} = field;
  const [showCounters] = useState(false);
  const hidden = extractFieldState(state, name).hidden;
  const theme = useTheme();
  const isMobileViewActive = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      {hidden || (
        <>
          <Grid
            item
            sm={toSize(field)}
            style={{
              position: 'relative',
              ...(isMobileViewActive ? {maxWidth: '100%'} : {}),
            }}
            xs={6}
          >
            {showCounters && <RenderCount />}
            <SpartanFieldWrapper {...props} />
          </Grid>
        </>
      )}
    </>
  );
}

function SpartanFieldWrapper(_props: FormFieldProps) {
  const {t} = useTranslation();
  const helper = useFormHelper();
  const {field, drillDownProps} = _props;
  const {state, onChange: initialOnChange, onBlur, onFocus} = drillDownProps;
  const {field_name: name, field_type: type} = field;
  const fieldState: FromFieldState = extractFieldState(state, name);
  const value = state.values && state.values[name];
  const showError = fieldState.touched || fieldState.changed;
  const error = showError ? state.errors[name] : undefined;
  const hasError = !!error;
  const placeholder = field.label || name;
  let label = resolveLabelTemplate(field.label_template, state.values) || field.label || name;
  if (fieldState.required) label += '*';

  const tooltip_tk = field?.tooltip_translation_key;
  const tooltip = tooltip_tk && <p dangerouslySetInnerHTML={{__html: t(tooltip_tk)}}></p>;

  const props: SpartanFieldProps = {
    id: field.field_id,
    name,
    label,
    placeholder,
    value: toControlledComponentValue(field.field_type, value),
    disabled: fieldState.disabled,
    onChange: useCallback(
      decorateOnChange(initialOnChange, (value) => fromControlledComponentValue(field.field_type, value)),
      [initialOnChange]
    ),
    onBlur,
    onFocus,
    hasError: hasError,
    error: error,
    tooltip,
  };

  function toOption(option: SpartanFieldOption): SpartanFieldOption {
    if (option.value === undefined || option.value === null) return option;
    const sanitizedValue = option.value
      .toString()
      .replaceAll(/\W+/g, '_') // replace all non chars with _
      .replaceAll(/(^_|_$)/g, '') // drop leading/trailing underscores
      .toLowerCase();
    const label = t(`feature.${helper.formId}.field.${field.field_name}.option.${sanitizedValue}`, option.label);
    return {...option, label};
  }

  function toOptions(field: FormFieldSchema): SpartanFieldOption[] {
    if (state.options[name]) {
      return state.options[name].map(toOption);
    }
    return field.options || [];
  }
  switch (type) {
    case FormFieldType.NOTE:
      return <SpartanNoteField {...props} />;
    case FormFieldType.TEXT:
      return <SpartanTextField {...props} />;
    case FormFieldType.PHONE:
      // TODO Make a refactor to take this from BE: CRM1-561
      const auth0 = Auth0ContextHolder.getInstance().get();
      if (getOrgName(auth0?.user)?.includes('favtel')) return <SpartanPhoneFavtelField {...props} />;
      return <SpartanPhoneField {...props} />;
    case FormFieldType.EXTENSION:
      return <SpartanExtensionField {...props} />;
    case FormFieldType.ZIPCODE:
      return <SpartanZipCodeField {...props} />;
    case FormFieldType.EMAIL:
      return <SpartanEmailField {...props} />;
    case FormFieldType.PASSWORD:
      return <SpartanPasswordField {...props} />;
    case FormFieldType.NUMBER:
    case FormFieldType.FLOAT:
      return <SpartanNumberField {...props} />;
    case FormFieldType.TEXTAREA:
      return <SpartanTextAreaField {...props} />;
    case FormFieldType.SUBMIT_TEXTAREA:
      return <SpartanSubmitTextAreaField {...props} />;
    case FormFieldType.RICH_TEXT_EDITOR:
      return <SpartanRichTextField {...props} />;
    case FormFieldType.SELECT:
      return <SpartanSelectField {...props} options={toOptions(field)} />;
    case FormFieldType.SELECTMULTIPLE:
      return <SpartanMultiSelectField {...props} options={toOptions(field)} />;
    case FormFieldType.CHECKBOXINLINE:
      return <SpartanCheckboxField {...props} />;
    case FormFieldType.CHECKBOXES:
      return <SpartanCheckboxesField {...props} options={toOptions(field)} />;
    case FormFieldType.RADIOS:
      return <SpartanRadioGroupField {...props} options={toOptions(field)} />;
    case FormFieldType.DATE:
      return <SpartanDateField {...props} minDate={field.min_value} maxDate={field.max_value} />;
    case FormFieldType.TIME:
      return <SpartanTimeField {...props} minTime={field.min_value} maxTime={field.max_value} />;
    case FormFieldType.TIMESTAMP:
    case FormFieldType.DATETIME:
      const use_timezone = field.metadata?.use_timezone ?? false;
      return use_timezone ? (
        <SpartanDateTimeWithTzField {...props} minDateTime={field.min_value} maxDateTime={field.max_value} />
      ) : (
        <SpartanDateTimeField {...props} minDateTime={field.min_value} maxDateTime={field.max_value} />
      );
    case FormFieldType.AUTO_ADDRESS:
      return <SpartanAutoAddressField {...props} />;
    case FormFieldType.AUTO_FILL_OPTION_CARD:
      return <SpartanAutoFillOptionCardField {...props} groupInfo={field?.metadata} />;
    case FormFieldType.CREDIT:
      return <SpartanCreditField {...props} />;
    case FormFieldType.LABEL:
      return <SpartanLabelField {...props} />;
    case FormFieldType.CVV:
      return <SpartanCvvField {...props} />;
    case FormFieldType.SOCIAL:
      return <SpartanSocialField {...props} />;
    case FormFieldType.CURRENCY:
      return <SpartanCurrencyField {...props} />;
    case FormFieldType.COMMUNICATION:
      return <SpartanCommunicationField />;
    case FormFieldType.HISTORY:
      return <SpartanHistoryField leadId={state?.initialValues?.lead_id} />;
    case FormFieldType.INSX:
      return <SpartanINSXField lead={state.values as Lead} />;
    case FormFieldType.COMMENTS:
      return <SpartanCommentField comments={value} options={toOptions(field)} />;
    case FormFieldType.SEARCH:
      return <SpartanSearchField {...props} />;
    case FormFieldType.UPLOAD:
      return <SpartanFileUploader fieldProps={props} formValues={state?.values} />;
    case FormFieldType.TIME_BETWEEN_CALLS:
      return <SpartanTBCField {...props} formValues={state?.values} />;
    case FormFieldType.LINK:
      props.tooltip = t(`shared.link-tooltip-message`);
      return <SpartanLinkField {...props} formValues={state?.initialValues} linkValue={field?.metadata} />;
    default:
      return (
        <>
          <Typography style={{fontSize: 'smaller'}}>
            Field <code>{name}</code> of unknown type <code>{type}</code>
          </Typography>
          <ErrorMessage hasError={hasError} error={error} />
        </>
      );
  }
}
