import React, {FunctionComponent, useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useAuth0} from '@auth0/auth0-react';
import {filter} from 'rxjs/operators';
import {
  Backdrop,
  Badge,
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Toolbar,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar/AppBar';
import {CSSObject, styled, Theme} from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import {AccountCircle} from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {ReactComponent as DHLogo} from '../../DH_logo.svg';
import {ReactComponent as LogoSm} from '../../logo-sm.svg';
import TalkDialog from './dialogs/TalkDialog';
import EmailDialog from './dialogs/EmailDialog';
import SideNav from './SideNav';
import AccessControl, {UserPermissions} from '../components/shared/AccessControl';
import {FeatureName, Path} from '../../paths';
import {Navbar as NavBarFeature} from '../model/Navbar';
import {OrganizationRole, OrganizationRoleName} from '../model/Organization';
import UserRoleService from '../services/UserRoleService';
import {
  Auth0User,
  Auth0UserPermission,
  getCanImpersonateUserRoles,
  getUserId,
  getUserRoleId,
  hasUserPermission,
  isPowerOrg,
} from '../model/Auth0User';
import {Settings, Support, SupportType} from '../model/Settings';
import {SpartanMenuItem} from '../model/SpartanMenuItems';
import {getDynamicTranslationKey} from '../../i18n/Utils';
import {WebSocketCampaignService} from '../services/WebSocketCampaignService';
import {WebSocketCampaignCallNow, WebSocketCampaigns} from '../model/WebSocketCampaign';
import {TestAttributes} from '../TestAttributes';
import LoggedInCampaignService from '../services/LoggedInCampaignService';
import {LoggedInCampaign} from '../model/Campaign';
import {NextgenFormSchema} from '../form/api/middleware/NextgenFormSchema';
import NotificationService, {NotificationType} from '../services/NotificationService';
import {AxiosResponse} from 'axios';
import {useAxiosContext} from '../context/AxiosContext';
import {useLoading} from '../context/LoadingContext';
import {useConfig} from '../context/ConfigContext';

const drawerWidth = 260;
const drawerWidthMobileView = 300;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)} + 16px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 8px)`,
  },
});

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 2),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const CustomDrawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(({theme, open}) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      marginLeft: drawerWidthMobileView,
      width: `calc(100% - ${drawerWidthMobileView}px)`,
    },
  }),
}));

function prepareCallNowCampaignsForUser(
  campaigns: WebSocketCampaignCallNow[],
  userId: string | undefined
): WebSocketCampaignCallNow[] {
  return campaigns
    .filter((campaign) => userId && campaign.users.some((user_id) => user_id === userId) && campaign.active_hours)
    .sort((a, b) => a.priority - b.priority);
}

function countTotalMessages(campaigns: WebSocketCampaignCallNow[]) {
  return campaigns.reduce((sum, c) => sum + c.messages, 0);
}

function findHighestPrioCallNowCampaignId(callNowCampaigns: WebSocketCampaignCallNow[]): string | null {
  const nextCallNowCampaign = callNowCampaigns.sort((a, b) => a.priority - b.priority).find((c) => c.messages > 0);
  return nextCallNowCampaign?.campaign_id ?? null;
}

interface NavbarProps {
  hideSideNav: boolean | undefined;
}

const Navbar: FunctionComponent<NavbarProps> = ({hideSideNav}) => {
  const {useAxiosBFF, useAxiosFormAPI} = useAxiosContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {CALL_NOW_BUTTON_DELAY} = useConfig();
  const navigate = useNavigate();
  const location = useLocation();
  const auth0 = useAuth0<Auth0User>();
  const {isAuthenticated, logout, user} = auth0;
  const userId = getUserId(user);
  const {t} = useTranslation();
  const {loading, setLoading} = useLoading();
  const isPower = isPowerOrg(user);
  const canViewSystemSettings = hasUserPermission(user, Auth0UserPermission.VIEW_SYSTEM_SETTINGS);
  const [open, setOpen] = React.useState(!isMobile);
  const [isTalkDialogOpen, setIsTalkDialogOpen] = useState<boolean>(false);
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState<boolean>(false);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [helpAnchorEl, setHelpAnchorEl] = useState<null | HTMLElement>(null);
  const [callSupport, setCallSupport] = useState<Support>();
  const [chatSupport, setChatSupport] = useState<Support>();
  const [emailSupport, setEmailSupport] = useState<Support>();
  const [userPermissions, setUserPermissions] = useState<Array<UserPermissions>>([]);
  const [callNowCampaigns, setCallNowCampaigns] = useState<WebSocketCampaignCallNow[]>();
  const [callNowCampaignBatchSize, setCallNowCampaignBatchSize] = useState<number>(0);
  const [callNowId, setCallNowId] = useState<string | null>(null);
  const [callOption, setCallOption] = useState<number>(1);
  const DrawerComponent = isMobile ? MuiDrawer : CustomDrawer;
  const [roles, setRoles] = useState<Array<OrganizationRole>>([]);
  const [accountMenuItems, setAccountMenuItems] = useState<Array<SpartanMenuItem>>([]);
  const [disabledCallNow, setDisabledCallNow] = useState<boolean>(true);
  const [loggedCampaign, setLoggedCampaign] = useState<LoggedInCampaign>();
  const [selectedRole, setSelectedRole] = useState<string | null>();

  const [{data: settingsData, loading: settingLoading}] = useAxiosBFF<Settings>(`/${FeatureName.SETTINGS}`, {
    manual: false,
  });
  const [{response: getMenuItemsResponse, error: getMenuItemsError, loading: itemsLoading}, getMenuItems] =
    useAxiosBFF<NextgenFormSchema>(
      {
        url: `/featurefields`,
        method: 'GET',
        params: {feature_name: FeatureName.NAVBAR},
      },
      {manual: true, autoCancel: true}
    );

  const [{error: getNavbarError}, getNavbar] = useAxiosFormAPI<NavBarFeature>(
    {
      url: '/feature/navbar',
      method: 'GET',
    },
    {manual: true}
  );

  useEffect(() => {
    const sub = UserRoleService.getInstance()
      .getSelectedRole()
      .subscribe((role) => setSelectedRole(role));
    return () => sub.unsubscribe();
  }, []);

  useEffect(() => {
    if (getMenuItemsResponse) {
      const profileItems = getMenuItemsResponse?.data?.menu_items?.find((item) => item.label === 'account');
      setAccountMenuItems(profileItems?.children || []);
    }
  }, [getMenuItemsResponse]);

  useEffect(() => {
    setLoading(settingLoading || itemsLoading, 'Navbar');
  }, [settingLoading, itemsLoading]);

  useEffect(() => {
    if (getMenuItemsError?.response) {
      NotificationService.getInstance().sendNotification(
        getMenuItemsError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [getMenuItemsError]);

  useEffect(() => {
    const subscription = LoggedInCampaignService.getInstance()
      .getLoggedCampaign()
      .subscribe((loggedInCampaign) => {
        setLoggedCampaign(loggedInCampaign);
      });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const _subscription = WebSocketCampaignService.getInstance()
      .listen()
      .subscribe((campaigns: WebSocketCampaigns) => {
        setCallNowCampaigns(prepareCallNowCampaignsForUser(campaigns.call_now ?? [], userId));
      });
    return () => _subscription.unsubscribe();
  }, [userId]);

  useEffect(() => {
    if (callNowCampaigns) {
      const totalMessages = countTotalMessages(callNowCampaigns);
      if (totalMessages > 0) {
        setCallNowCampaignBatchSize(totalMessages);
        setCallNowId(findHighestPrioCallNowCampaignId(callNowCampaigns));
      } else {
        setCallNowCampaignBatchSize(0);
        setCallNowId(null);
      }
    }
  }, [callNowCampaigns]);

  useEffect(() => {
    setDisabledCallNow(callNowCampaignBatchSize <= 0);
  }, [callNowCampaignBatchSize]);

  useEffect(() => {
    if (disabledCallNow) {
      setTimeout(() => {
        if (callNowCampaignBatchSize > 0) {
          setDisabledCallNow(false);
        }
      }, CALL_NOW_BUTTON_DELAY);
    }
  }, [disabledCallNow, callNowCampaignBatchSize]);

  useEffect(() => {
    if (isAuthenticated) {
      const subscription = UserRoleService.getInstance()
        .getSelectedRole()
        .pipe(filter((selectedRole) => !!selectedRole))
        .subscribe(() => {
          getNavbar().then((navbarResponse: AxiosResponse<NavBarFeature>) => {
            setUserPermissions(navbarResponse?.data.actions);
          });
        });
      return () => subscription.unsubscribe();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (getNavbarError) {
      NotificationService.getInstance().sendNotification(
        getNavbarError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [getNavbarError]);

  useEffect(() => {
    if (isAuthenticated && settingsData) {
      const supportItems: Array<Support> = settingsData?.support;
      const supportItemCall = supportItems?.find((support) => support.type === SupportType.CALL);
      const supportItemChat = supportItems?.find((support) => support.type === SupportType.CHAT);
      const supportEmail = supportItems?.find((support) => support.type === SupportType.EMAIL);
      setCallSupport(supportItemCall);
      setChatSupport(supportItemChat);
      setEmailSupport(supportEmail);
    }
  }, [isAuthenticated, settingsData]);

  useEffect(() => {
    if (selectedRole) {
      getMenuItems();
    }
  }, [selectedRole]);

  useEffect(() => {
    if (user) {
      const roles = isPower
        ? getCanImpersonateUserRoles(user)
        : getCanImpersonateUserRoles(user).filter((role) => !role.role_name.includes(OrganizationRoleName.POWER));
      setRoles(roles);
    }
  }, [user]);

  const handleSelectedRoleChange = (event: any) => {
    const selectedRoleId = event.target.value;
    LoggedInCampaignService.getInstance().setEmptyCampaign();
    UserRoleService.getInstance().setSelectedRole(selectedRoleId);
    navigate('/' + Path.DASHBOARD);
  };

  const handleUserIconClick = (event: any) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
  };

  const handleHelpMenuClose = () => {
    setHelpAnchorEl(null);
  };
  const handleHelpIconClick = (event: any) => {
    setHelpAnchorEl(event.currentTarget);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function checkIfSomePermission(
    userPermissions: Array<UserPermissions>,
    allowedPermissions: Array<UserPermissions>
  ): boolean {
    return allowedPermissions.some((allowedPermission) => userPermissions?.includes(allowedPermission));
  }

  return (
    <>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          {isMobile && !hideSideNav && (
            <IconButton
              id="navbar-menu"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: {xs: '15px', sm: '36px'},
                marginLeft: '0px',
                ...(open && {display: 'none'}),
              }}
            >
              <MenuIcon />
            </IconButton>
          )}

          <div>
            <Box sx={{display: {xs: 'none', sm: 'block'}, width: '275px'}}>
              <Link to={'/' + Path.DASHBOARD}>
                <DHLogo />
              </Link>
            </Box>
          </div>
          <Box sx={{flexGrow: 1}} />

          {loggedCampaign?.id && (
            <Tooltip title={loggedCampaign.campaignName}>
              <Button
                {...{[TestAttributes.BUTTON_NAME]: 'campaign-register'}}
                variant="outlined"
                color="secondary"
                size={'small'}
                disabled={loading}
                onClick={() => navigate(`/${FeatureName.CAMPAIGNS}/view/${loggedCampaign?.id}?timestamp=${Date.now()}`)}
                sx={{
                  marginLeft: '16px',
                  marginRight: '5px',
                  maxWidth: '250px',
                  height: '34px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  textOverflow: 'ellipsis',
                  justifyContent: 'flex-start',
                }}
                startIcon={<FiberManualRecordIcon sx={{color: '#089718', fontSize: '1rem'}} />}
              >
                {loggedCampaign.campaignName}
              </Button>
            </Tooltip>
          )}
          <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.VIEW_CALL_NOW]}>
            <FormControl sx={{padding: (theme) => `${theme.spacing(2)}`}}>
              <Badge badgeContent={callNowCampaignBatchSize} color="error">
                <Button
                  disabled={disabledCallNow || loading}
                  {...{[TestAttributes.BUTTON_NAME]: 'callnow-btn'}}
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    setDisabledCallNow(true);
                    setCallOption(callOption + 1);
                    const prevLocation = location.pathname;
                    navigate(`/campaigns/view/${callNowId}?timestamp=${Date.now()}`, {
                      state: {callOption, prevLocation},
                    });
                  }}
                >
                  {t('leads.dialogs.call.call-now')}
                </Button>
              </Badge>
            </FormControl>
          </AccessControl>
          {user && (
            <FormControl
              sx={{
                width: '150px',
                [theme.breakpoints.down('sm')]: {
                  display: open ? 'none' : 'block',
                },
              }}
            >
              <Select
                id="selected-role-select"
                {...{[TestAttributes.FIELD_NAME]: 'selected-role-select'}}
                value={selectedRole || getUserRoleId(user)}
                onChange={handleSelectedRoleChange}
              >
                {roles.map((role: OrganizationRole) => (
                  <MenuItem
                    key={'role-' + role.role_name}
                    id={'menu-item-role-' + role.role_name}
                    value={role.role_id}
                    {...{[TestAttributes.FIELD_OPTION_NAME]: `menu-item-role-${role.role_name}`}}
                  >
                    {t(getDynamicTranslationKey('roles', role.role_name))}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {isAuthenticated && (
            <>
              {checkIfSomePermission(userPermissions, [
                UserPermissions.VIEW_TALK_SUPPORT,
                UserPermissions.VIEW_CHAT_SUPPORT,
                UserPermissions.VIEW_EMAIL_SUPPORT,
              ]) && (
                <IconButton
                  id="navbar-contact-support"
                  {...{[TestAttributes.BUTTON_NAME]: 'navbar-contact-support'}}
                  onClick={handleHelpIconClick}
                  color="inherit"
                  sx={{
                    [theme.breakpoints.down('sm')]: {
                      display: open ? 'none' : 'block',
                    },
                  }}
                >
                  <ContactSupportIcon />
                </IconButton>
              )}

              <IconButton
                id="navbar-user-profile"
                {...{[TestAttributes.BUTTON_NAME]: 'navbar-user-profile'}}
                onClick={handleUserIconClick}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </>
          )}
          <Menu
            id="help-menu"
            anchorEl={helpAnchorEl}
            keepMounted
            open={Boolean(helpAnchorEl)}
            onClose={handleHelpMenuClose}
          >
            {callSupport && (
              <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.VIEW_TALK_SUPPORT]}>
                <MenuItem
                  id="navbar-help-menu-item-talk-support"
                  {...{[TestAttributes.BUTTON_NAME]: 'navbar-help-menu-item-talk-support'}}
                  onClick={() => {
                    handleHelpMenuClose();
                    setIsTalkDialogOpen(true);
                  }}
                >
                  {t('supportDialogs.talk-support')}
                </MenuItem>
              </AccessControl>
            )}

            {chatSupport && (
              <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.VIEW_CHAT_SUPPORT]}>
                <MenuItem
                  id="navbar-help-menu-item-chat-support"
                  onClick={handleHelpMenuClose}
                  {...{[TestAttributes.BUTTON_NAME]: 'navbar-help-menu-item-chat-support'}}
                >
                  <a href={chatSupport.contact_key} target="_blank" rel="noreferrer">
                    {t('supportDialogs.chat-support')}
                  </a>
                </MenuItem>
              </AccessControl>
            )}

            {emailSupport && (
              <AccessControl
                userPermissions={userPermissions}
                allowedPermissions={[UserPermissions.VIEW_EMAIL_SUPPORT]}
              >
                <MenuItem
                  id="navbar-help-menu-item-email-support"
                  {...{[TestAttributes.BUTTON_NAME]: 'navbar-help-menu-item-email-support'}}
                  onClick={() => {
                    handleHelpMenuClose();
                    setIsEmailDialogOpen(true);
                  }}
                >
                  {t('supportDialogs.email-support')}
                </MenuItem>
              </AccessControl>
            )}
          </Menu>

          {callSupport && (
            <TalkDialog
              isOpen={isTalkDialogOpen}
              handleClose={() => setIsTalkDialogOpen(false)}
              support={callSupport}
            />
          )}

          {emailSupport && (
            <EmailDialog
              isOpen={isEmailDialogOpen}
              handleClose={() => setIsEmailDialogOpen(false)}
              support={emailSupport}
            />
          )}

          <Menu
            id="user-menu"
            anchorEl={userMenuAnchorEl}
            keepMounted
            open={Boolean(userMenuAnchorEl)}
            onClose={handleUserMenuClose}
          >
            {canViewSystemSettings && (
              <MenuItem id="navbar-user-menu-item-system-settings">
                <Link to={'/' + Path.SYS_SETTINGS_ROOT}>{t('system-settings.user-menu-item')}</Link>
              </MenuItem>
            )}
            {canViewSystemSettings && <Divider />}
            {accountMenuItems.map((menuItem: SpartanMenuItem) => (
              <Link to={menuItem.action || ''} key={menuItem.menu_item_id}>
                <MenuItem id={`navbar-user-menu-item-${menuItem.label.split('.').pop()}`} onClick={handleUserMenuClose}>
                  {t(menuItem.label)}
                </MenuItem>
              </Link>
            ))}
            <MenuItem
              id="navbar-user-menu-item-logout"
              onClick={() => {
                LoggedInCampaignService.getInstance().setEmptyCampaign();
                logout({returnTo: window.location.origin + '/login'});
              }}
            >
              {t('shared.logout')}
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      {!hideSideNav && (
        <>
          {isMobile && <Backdrop open={open} onClick={handleDrawerClose} sx={{zIndex: 10}} />}
          <DrawerComponent
            variant={isMobile ? 'persistent' : 'permanent'}
            open={open}
            sx={{
              ...(isMobile &&
                open && {
                  '& .MuiDrawer-paper': {
                    width: drawerWidthMobileView,
                    boxSizing: 'border-box',
                  },
                }),
            }}
          >
            <DrawerHeader>
              {isMobile && (
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
              )}
            </DrawerHeader>

            <SideNav isSideNavOpen={open} />
          </DrawerComponent>
        </>
      )}
    </>
  );
};

export default Navbar;
