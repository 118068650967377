import * as React from 'react';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import {MaterialUiIcons} from './MaterialUiIcons';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import {Fragment} from 'react';
import Icon from '@mui/material/Icon';
import {height} from '@amcharts/amcharts4/.internal/core/utils/Utils';
import {Height} from '@mui/icons-material';

interface Props {
  iconName: string;
  hasChildren: boolean;
  isCollapsed: boolean;
  color: string;
}

export default function SideNavMenuIcon({iconName, hasChildren, isCollapsed, color}: Props) {
  const MaterialUiIcon = MaterialUiIcons[iconName] ?? Icon;
  const fontSize = 'medium';
  const marginRight = '5px';
  const iconSx = {color: color, marginRight: {marginRight}, height: '28px'};

  return (
    <>
      {hasChildren ? (
        isCollapsed ? (
          <Fragment>
            <KeyboardArrowDownOutlinedIcon sx={iconSx} fontSize={fontSize} />
            <MaterialUiIcon sx={iconSx} fontSize={fontSize} />
          </Fragment>
        ) : (
          <Fragment>
            <KeyboardArrowRightOutlinedIcon sx={iconSx} fontSize={fontSize} />
            <MaterialUiIcon sx={iconSx} fontSize={fontSize} />
          </Fragment>
        )
      ) : (
        <Fragment>
          <Icon sx={iconSx} fontSize={fontSize} />
          <MaterialUiIcon sx={iconSx} fontSize={fontSize} />
        </Fragment>
      )}
    </>
  );
}
