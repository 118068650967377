import * as React from 'react';
import LeadsIcon from '@mui/icons-material/ContactPageOutlined';
import UsersIcon from '@mui/icons-material/PersonOutlined';
import TeamsIcon from '@mui/icons-material/PeopleAltOutlined';
import QueriesIcon from '@mui/icons-material/Search';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import CampaignsIcon from '@mui/icons-material/CampaignOutlined';
import TasksIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import SmsPortalIcon from '@mui/icons-material/ForumOutlined';
import SmsLogIcon from '@mui/icons-material/ListAltOutlined';
import CDRSearchIcon from '@mui/icons-material/PhoneInTalkOutlined';
import Insx from '@mui/icons-material/CloudOutlined';
import DashboardOutlined from '@mui/icons-material/DashboardOutlined';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import AutoActionIcon from '@mui/icons-material/FlashAutoOutlined';
import {Box} from '@mui/material';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import TextsmsRoundedIcon from '@mui/icons-material/TextsmsRounded';
import MailRoundedIcon from '@mui/icons-material/MailRounded';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded';
import VoicemailRoundedIcon from '@mui/icons-material/VoicemailRounded';
import SmartToyOutlined from '@mui/icons-material/SmartToyOutlined';

interface DynamicIconProps {
  iconName: string;
  color: string;
  fontSize: 'small' | 'inherit' | 'large' | 'medium' | undefined;
  hidden?: boolean;
}

export default function DynamicIcon({iconName, color, fontSize, hidden}: DynamicIconProps) {
  const featureIconSx = {color: color, verticalAlign: 'middle'};

  const featureIcons = {
    dashboard: <DashboardOutlined sx={featureIconSx} fontSize={fontSize} />,
    leads: <LeadsIcon sx={featureIconSx} fontSize={fontSize} />,
    users: <UsersIcon sx={featureIconSx} fontSize={fontSize} />,
    teams: <TeamsIcon sx={featureIconSx} fontSize={fontSize} />,
    queries: <QueriesIcon sx={featureIconSx} fontSize={fontSize} />,
    'lead-queries': <ManageSearchRoundedIcon sx={featureIconSx} fontSize={fontSize} />,
    campaigns: <CampaignsIcon sx={featureIconSx} fontSize={fontSize} />,
    tasks: <TasksIcon sx={featureIconSx} fontSize={fontSize} />,
    cdr: <CDRSearchIcon sx={featureIconSx} fontSize={fontSize} />,
    'sms-portal': <SmsPortalIcon sx={featureIconSx} fontSize={fontSize} />,
    'sms-log': <SmsLogIcon sx={featureIconSx} fontSize={fontSize} />,
    'insx-cloud': <Insx sx={featureIconSx} fontSize={fontSize} />,
    'sparta-tools': <LinkOutlinedIcon sx={featureIconSx} fontSize={fontSize} />,
    'recording-analytics': <MicNoneOutlinedIcon sx={featureIconSx} fontSize={fontSize} />,
    'lead-portal': <LeaderboardOutlinedIcon sx={featureIconSx} fontSize={fontSize} />,
    inbox: <MailOutlinedIcon sx={featureIconSx} fontSize={fontSize} />,
    settings: <SettingsOutlinedIcon sx={featureIconSx} fontSize={fontSize} />,
    'user-guide': <SupportAgentOutlinedIcon sx={featureIconSx} fontSize={fontSize} />,
    'automatic-actions': <AutoActionIcon sx={featureIconSx} fontSize={fontSize} />,
    'reporting-analytics': <InsightsRoundedIcon sx={featureIconSx} fontSize={fontSize} />,
    'creative-assistant': <WorkspacesOutlinedIcon sx={featureIconSx} fontSize={fontSize} />,
    'sms-campaigns': <TextsmsRoundedIcon sx={featureIconSx} fontSize={fontSize} />,
    'email-campaigns': <MailRoundedIcon sx={featureIconSx} fontSize={fontSize} />,
    'call-campaigns': <CallRoundedIcon sx={featureIconSx} fontSize={fontSize} />,
    operations: <BusinessOutlinedIcon sx={featureIconSx} fontSize={fontSize} />,
    'local-presence-dids': <LocationOnOutlinedIcon sx={featureIconSx} fontSize={fontSize} />,
    'payment-clearance': <PaymentRoundedIcon sx={featureIconSx} fontSize={fontSize} />,
    'rvm-log': <VoicemailRoundedIcon sx={featureIconSx} fontSize={fontSize} />,
    'ai-call-log': <SmartToyOutlined sx={featureIconSx} fontSize={fontSize} />,
  };

  return featureIcons[iconName];
}
