import {SpartanTextFieldProps} from './model';
import {withIMask} from './components/withIMask';
import * as React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import {SpartanTextField} from './SpartanTextField';
import PaymentsIcon from '@mui/icons-material/Payments';
function SpartanCurrencyFieldInternal({InputProps, ...props}: SpartanTextFieldProps) {
  return (
    <SpartanTextField
      {...props}
      InputProps={{
        ...InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <PaymentsIcon fontSize="small" />
          </InputAdornment>
        ),
      }}
    />
  );
}

function preProcessValue(value: any): string {
  return value === undefined ? '' : `${value}`;
}

function postProcessValue(unmaskedValue: string) {
  const parsed = parseFloat(unmaskedValue);
  return isNaN(parsed) ? null : parsed;
}

export const SpartanCurrencyField = withIMask(
  SpartanCurrencyFieldInternal,
  {
    mask: [
      {mask: ''},
      {
        mask: 'currency',
        lazy: false,
        blocks: {
          currency: {
            mask: Number,
            scale: 2,
            signed: true,
            thousandsSeparator: ',',
            padFractionalZeros: false,
            radix: '.',
            mapToRadix: ['.'],
          },
        },
      },
    ],
  },
  {preProcessValue, postProcessValue}
);
