export function getEnvVar(name: string): string | undefined {
  return process.env[`REACT_APP_${name}`];
}

export function getEnvVarOrDefault(name: string, defaultValue: string): string {
  const value = getEnvVar(name);
  return value === undefined ? defaultValue : value;
}

const IS_TEST = getEnvVarOrDefault('IS_TEST', 'false') === 'true';
const STRICT_MODE = getEnvVarOrDefault('STRICT_MODE', 'false') === 'true';
const BASE_URL = getEnvVarOrDefault('BASE_URL', '');
const CONFIG_URL = getEnvVarOrDefault('CONFIG_URL', buildConfigUrl(BASE_URL));

function buildConfigUrl(baseUrl: string) {
  if (window.location.hostname === 'localhost') {
    const orgName = getEnvVar('ORG_NAME_OVERRIDE');
    if (orgName) {
      return `${baseUrl}/api/config/v1/frontend-config/${orgName}`;
    }
  }
  return `${baseUrl}/api/config/v1/frontend-config`;
}

export interface Env {
  IS_TEST: boolean;
  STRICT_MODE: boolean;
  /**
   * This is the target environment that we want to use, example:
   * - https://demo.development.codemachine.app
   * - https://demo.testing.codemachine.app
   * - etc.
   * When running on k8s, this is left empty (empty string), which means that all requests urls are relative to the host where the FE is running.
   */
  BASE_URL: string;
  /**
   * Where to fetch the dynamic config.
   */
  CONFIG_URL: string;
  /**
   * ATTENTION: do not add configuration parameters here, instead add parameter to src/app/Config.ts (define a hardcoded default value if it makes sense).
   * In case you need different values for different envs, insert into table frontend_config (ask support to BE devs).
   * In case you need a different value for local development, add `REACT_APP_OVERRIDE_CONFIG_{PARAM_NAME}=PARAM_VALUE` to .env.local file.
   */
}

export const env: Env = {
  IS_TEST,
  STRICT_MODE,
  BASE_URL,
  CONFIG_URL,
};

global['__ENV__'] = env;
