import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {Box, Button, Container, Grid, Skeleton, Typography} from '@mui/material';
import {Lead} from '../../model/Lead';
import {useFormAPI} from '../../form/FormAPI';
import LeadEditor from './LeadEditor';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import {FeatureName} from '../../../paths';
import {useTranslation} from 'react-i18next';
import {DefaultResponse} from '../../services/model/DefaultResponse';
import {parseNextgenFormSchema} from '../../form/api/middleware/parseNextgenFormSchema';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {Organization, OrganizationName} from '../../model/Organization';
import {generatePdf} from './utils/GeneratePdf';
import OrganizationService from '../../services/OrganizationService';
import {useNavigate} from 'react-router-dom';
import {FolderPath} from '../../model/Files';
import {TestAttributes} from '../../TestAttributes';
import {useFiles} from '../../UseFiles';
import {NextgenFormSchema} from '../../form/api/middleware/NextgenFormSchema';
import {AxiosResponse} from 'axios';
import {HttpMethods} from '../../model/HttpMethods';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';
import FeatureNotFound from '../notFound/FeatureNotFound';

function LeadPage() {
  const {useAxiosBFF} = useAxiosContext();
  const {id} = useParams();
  const {t} = useTranslation();
  const {setLoading} = useLoading();
  const formAPI = useFormAPI();
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [lead, setLead] = useState<Lead | null>(null);
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.LEADS);
  const [headlineName, setHeadlineName] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(true);
  const {getFileContent, getSignature} = useFiles();
  const [{error: getSchemaError, loading: isGetSchemaLoading}, getSchema] = useAxiosBFF<NextgenFormSchema>(
    {
      url: `/featurefields`,
      method: HttpMethods.GET,
      params: {feature_name: FeatureName.LEADS},
    },
    {manual: true}
  );

  const [{loading: isGetLeadsByIdLoading, error: hasGetLeadByIdError}, getLeadsById] = useAxiosBFF<Lead>(
    HttpMethods.GET,
    {manual: true}
  );

  useEffect(() => {
    const loading = isGetLeadsByIdLoading || isGetSchemaLoading;
    setLoading(loading, 'LeadPage');
  }, [isGetLeadsByIdLoading, isGetSchemaLoading]);

  useEffect(() => {
    if (getSchemaError) {
      NotificationService.getInstance().sendNotification(
        getSchemaError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [getSchemaError]);

  useEffect(() => {
    if (hasGetLeadByIdError) {
      setIsValid(false);
    }
  }, [hasGetLeadByIdError]);

  useEffect(() => {
    const subscription = OrganizationService.getInstance()
      .getOrganization()
      .subscribe((org) => {
        setOrganization(org);
      });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (id) {
      getLead(id);
    } else {
      setLead(null);
    }
  }, [id]);

  function getLead(leadId?: string) {
    if (leadId) {
      getLeadsById({url: `/${FeatureName.LEADS}/${leadId}`, params: {formatted: true}}).then(
        (leadResponse: AxiosResponse<Lead>) => {
          setLead(leadResponse.data);
        }
      );
    }
  }
  useEffect(() => {
    if (organization && lead) {
      const headlineName: string =
        organization.name === OrganizationName.PBC
          ? ` ${lead[organization.lead_name_key] || ''} `
          : ` ${lead[organization.lead_name_key] || ''} ${lead[organization.last_name_key] || ''}`;
      setHeadlineName(headlineName);
    }
  }, [organization, lead]);

  function createLeadPdf(leadId: string): void {
    const orgName = OrganizationService.getInstance().getCurrentValue()?.name;
    const headerParams = {
      filename: 'Header.jpg',
      folder: 'attachments',
      type: 'download',
    };

    Promise.all([
      getSignature(`${FolderPath.LEADS_ATTACHMENTS}${leadId}/sig/`),
      getFileContent(headerParams),
      getSchema(),
    ])
      .then(([signature, header, response]) => {
        if (!signature) {
          NotificationService.getInstance().sendNotification(
            t('leads.missing-signature-message'),
            NotificationType.WARNING
          );
        }

        if (signature && lead && orgName && response) {
          const formSchema = parseNextgenFormSchema(response.data);
          generatePdf(`${lead.lead_id}_application`, formSchema?.sections || [], lead, header, signature, orgName);
        }
      })
      .catch(() => {
        NotificationService.getInstance().sendNotification('Error generating the pdf', NotificationType.WARNING);
      });
  }

  const navigate = useNavigate();

  function onSubmitSucceeded(response: AxiosResponse<DefaultResponse>, formValues: any) {
    if (response?.status === 201) {
      navigate(`/${FeatureName.LEADS}/${response.data.id}`);
    } else if (response?.status === 200) {
      getLead(lead?.lead_id);
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        {isValid ? (
          <Grid container spacing={3}>
            {isGetLeadsByIdLoading ? (
              <Skeleton variant="rectangular" height={500} />
            ) : (
              <LeadEditor
                userPermissions={userPermissions}
                lead={lead}
                subHeadline={headlineName}
                headline={`${t('leads.headline')}`}
                setLead={setLead}
                onSubmitSucceeded={onSubmitSucceeded}
                formAPI={formAPI}
              >
                <AccessControl
                  userPermissions={userPermissions}
                  allowedPermissions={[UserPermissions.VIEW_UPLOAD_CENTER]}
                >
                  <Button
                    color="secondary"
                    id={'upload-center-btn'}
                    {...{[TestAttributes.BUTTON_NAME]: 'upload-center-btn'}}
                    target="_blank"
                    href={'https://www.probizcap.com/upload-center/?app_id=' + lead?.lead_id}
                  >
                    {t('leads.upload-center')}
                  </Button>
                </AccessControl>
                {id && (
                  <AccessControl
                    userPermissions={userPermissions}
                    allowedPermissions={[UserPermissions.VIEW_GENERATE_PDF]}
                  >
                    <Button
                      color="secondary"
                      id={'create-pdf-btn'}
                      {...{[TestAttributes.BUTTON_NAME]: 'create-pdf-btn'}}
                      onClick={() => createLeadPdf(id)}
                    >
                      {t('leads.create-pdf')}
                    </Button>
                  </AccessControl>
                )}
              </LeadEditor>
            )}
          </Grid>
        ) : (
          <FeatureNotFound />
        )}
      </Container>
    </Box>
  );
}

export default LeadPage;
