import {InputLabel} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Controller, useFormContext} from 'react-hook-form';
import {ErrorMessage} from '../../../form/fields/components/ErrorMessage';
import {QueryFieldSchema} from '../../../model/Query';
import SpartanSortableMultiSelect from '../../../form/fields/SpartanSortableMultiSelect/SpartanSortableMultiSelect';
import {SpartanFieldOption, SpartanFieldProps} from '../../../form/fields/model';

interface QueryFieldsProps {
  fields: Array<QueryFieldSchema>;
}

function QueryFieldsSortable({fields}: QueryFieldsProps) {
  const {t} = useTranslation();
  const {control} = useFormContext();
  const sortableAutoCompleteId = 'sortable-fields-to-display';
  const requiredErrorMsj = t('query.error-messages.required-value');

  function findOptionsByValues(options: QueryFieldSchema[], values: string[]): QueryFieldSchema[] {
    const orderedFields: QueryFieldSchema[] = [];
    const filterFieldsPositions = {};
    let index = 0;
    const fieldsToDisplay = options?.filter((opt: QueryFieldSchema) => {
      const fieldInValues = values?.indexOf(opt?.field_name) >= 0;
      if (fieldInValues) {
        filterFieldsPositions[opt.field_name] = index;
        index++;
      }
      return fieldInValues;
    });
    values?.forEach((orderedField) => {
      const orderedFieldInfo = fieldsToDisplay[filterFieldsPositions[orderedField]];
      if (orderedFieldInfo) {
        orderedFields.push(orderedFieldInfo);
      }
    });

    return orderedFields;
  }

  function toOptions(fields: Array<QueryFieldSchema>): SpartanFieldOption[] {
    const options = fields.map((item: QueryFieldSchema, index: number) => ({
      value: item.field_id,
      label: item.label,
      field_id: item.field_id,
      field_name: item.field_name,
      order: index,
      is_required: item.is_required,
    }));
    return options as SpartanFieldOption[];
  }

  return (
    <>
      <Controller
        control={control}
        name="query_fields"
        rules={{required: requiredErrorMsj}}
        render={({field: {onChange, onBlur, value}, fieldState: {error}}) => {
          const hasErrorMessage = error ? error.message : undefined;
          const hasError = !!hasErrorMessage;
          const optionsByValues = findOptionsByValues(fields, value);
          function onFocus(e: any): void {}
          const spartanFieldProps: SpartanFieldProps = {
            onChange: onChange,
            onFocus: onFocus,
            onBlur: onBlur,
            id: sortableAutoCompleteId,
            label: 'sortable query fields',
            name: 'query_fields',
            value: optionsByValues.map((item: QueryFieldSchema) => ({
              value: item.field_id,
              label: item.label,
              field_name: item.field_name,
              is_required: item.is_required,
              field_id: item.field_id,
            })),
            placeholder: t('query.sortable-fields-to-display'),
          };

          return (
            <>
              <InputLabel htmlFor={sortableAutoCompleteId} error={hasError}>
                {t('query.sortable-fields-to-display')}
              </InputLabel>
              <SpartanSortableMultiSelect options={toOptions(fields)} {...spartanFieldProps} />
              <ErrorMessage hasError={hasError} error={hasErrorMessage} />
            </>
          );
        }}
      />
    </>
  );
}

export default QueryFieldsSortable;
