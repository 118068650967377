import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {filter} from 'rxjs/operators';
import {Box, Button, Container, Grid, IconButton, Menu, MenuItem} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {GridCellParams, GridColDef, GridRowModel} from '@mui/x-data-grid';
import {Skeleton} from '@mui/lab';
import {PageTopActions} from '../PageTopActions';
import PageHeadline from '../../components/PageHeadline';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import OrganizationService from '../../services/OrganizationService';
import {CampaignDialerType, CampaignListItem, CampaignToCopy} from '../../model/Campaign';
import {Status} from '../../model/Feature';
import {FeatureName} from '../../../paths';
import {PageStickyHeader} from '../PageStickyHeader';
import {Organization} from '../../model/Organization';
import {formatDateTime} from '../../utils/DateUtils';
import CopyCampaignDialog from './dialogs/CopyCampaignDialog';
import {useTableColumns} from '../UseTableColumns';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import DataGridCustom from '../../components/DataGridCustom';
import {TestAttributes} from '../../TestAttributes';
import {ColumnSizes} from '../../components/shared/ColumnSize';
import SpartanSnackbar from '../../components/SpartanSnackbar';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {useFiles} from '../../UseFiles';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';
import useAwsBucket from '../../hooks/useAwsBucket';

function CampaignListPage() {
  const {useAxiosBFF} = useAxiosContext();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState<Array<CampaignListItem> | null>(null);
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.CAMPAIGNS);
  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedCampaign, setSelectedCampaign] = useState<CampaignListItem | null>(null);
  const [organization, setOrganization] = useState<Organization | null>();
  const [isCopyCampaignDialogOpen, setIsCopyCampaignDialogOpen] = useState<boolean>(false);
  const [actionColumn, setActionColumn] = useState<GridColDef>();
  const {setLoading} = useLoading();
  const {postFiles} = useFiles();
  const awsBucket = useAwsBucket();
  const {campaignType} = useParams();

  const [
    {response: getListCampaignResponse, loading: isEntityListLoading, error: hasEntityListError},
    getAllCampaigns,
  ] = useAxiosBFF<ResponseListWrapper<CampaignListItem>>(
    {
      url: `/${FeatureName.CAMPAIGNS}`,
      method: 'GET',
      params: {formatted: true, campaign_type: campaignType?.replaceAll('-', '_')},
    },
    {manual: true}
  );

  const [{error: patchCampaignError, loading: patchCampaignLoading}, patchCampaignData] = useAxiosBFF<CampaignListItem>(
    {url: `/${FeatureName.CAMPAIGNS}/${selectedCampaign?.campaign_id}`, method: 'PATCH'},
    {manual: true}
  );
  const [
    {response: postCopyCampaignResponse, error: postCopyCampaignError, loading: postCampaignLoading},
    postCopyCampaignData,
  ] = useAxiosBFF<CampaignListItem>(
    {url: `/${FeatureName.CAMPAIGNS}`, params: {copy: true}, method: 'POST'},
    {manual: true}
  );

  useEffect(() => {
    setLoading(isEntityListLoading || patchCampaignLoading || postCampaignLoading, 'CampaignListPage');
  }, [isEntityListLoading, patchCampaignLoading, postCampaignLoading]);

  useEffect(() => {
    if (patchCampaignError) {
      NotificationService.getInstance().sendNotification(
        patchCampaignError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [patchCampaignError]);

  useEffect(() => {
    if (postCopyCampaignError) {
      NotificationService.getInstance().sendNotification(
        postCopyCampaignError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [postCopyCampaignError]);

  useEffect(() => {
    setActionColumn({
      field: 'actions',
      headerName: t('table-columns.actions'),
      disableExport: true,
      filterable: false,
      width: ColumnSizes.MD,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <IconButton
              id="campaign-list-more-actions-btn"
              aria-haspopup="true"
              onClick={(event: React.MouseEvent<HTMLElement>) => handleActionsMenuIconClick(event, params)}
              size="large"
            >
              <MoreHorizIcon />
            </IconButton>
          </>
        );
      },
    });
  }, []);

  const tableColumns = useTableColumns({
    featureName: FeatureName.CAMPAIGNS,
    actionColumn, // this must go in state to prevent re-run on re-rendering
  });

  useEffect(() => {
    const subscription = OrganizationService.getInstance()
      .getOrganization()
      .pipe(filter((organization) => organization !== null))
      .subscribe((organization: Organization | null) => {
        setOrganization(organization);
      });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (getListCampaignResponse) {
      setCampaigns(getListCampaignResponse?.data?.results || []);
    }
  }, [getListCampaignResponse]);

  useEffect(() => {
    if (postCopyCampaignResponse?.status === 201) {
      NotificationService.getInstance().sendNotification(
        postCopyCampaignResponse?.data?.['message'],
        NotificationType.SUCCESS
      );
      getAllCampaigns();
    }
  }, [postCopyCampaignResponse]);

  useEffect(() => {
    if (organization && campaignType) {
      getAllCampaigns();
    }
  }, [organization, campaignType]);

  function handleMoreActionsMenuClose(): void {
    setActionsMenuAnchorEl(null);
  }

  function onCopyCampaignSelected(): void {
    handleMoreActionsMenuClose();
    setIsCopyCampaignDialogOpen(true);
  }

  function changeCampaignStatus(selectedCampaign: CampaignListItem): void {
    handleMoreActionsMenuClose();
    const data = {
      status_campaign: selectedCampaign.status_campaign === Status.ACTIVE ? Status.INACTIVE : Status.ACTIVE,
    };
    patchCampaignData({
      data,
    }).then(getAllCampaigns);
  }

  function handleActionsMenuIconClick(event: React.MouseEvent<HTMLElement>, cell: GridCellParams): void {
    const {row}: GridRowModel = cell;
    setActionsMenuAnchorEl(event.currentTarget);
    setSelectedCampaign(row);
  }

  function copyCampaign(copiedCampaignName: string, file?: FormData, fileName?: string): void {
    const campaignToCopy: CampaignToCopy = {
      name: copiedCampaignName,
      campaign_id: selectedCampaign?.campaign_id || '',
    };
    if (file) {
      postFiles(file).then((response) => {
        campaignToCopy[
          'filename'
        ] = `/${awsBucket}/${FeatureName.CAMPAIGNS}/${response?.data?.campaign_id}/${fileName}`;
        campaignToCopy['campaign_id_tsv'] = response?.data?.campaign_id;
        postCopyCampaignData({data: campaignToCopy});
      });
    } else {
      postCopyCampaignData({
        data: campaignToCopy,
      });
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          {hasEntityListError && (
            <SpartanSnackbar type={NotificationType.ERROR} message={hasEntityListError.message}></SpartanSnackbar>
          )}
          <PageStickyHeader>
            <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
              <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                <PageHeadline>{t(`campaign.headline.${campaignType}`)}</PageHeadline>
              </Grid>
              <Grid item xs={12} md={6}>
                <PageTopActions>
                  <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.CREATE]}>
                    <Link to={`/${campaignType}-campaigns/create`}>
                      <Button fullWidth color="secondary" {...{[TestAttributes.BUTTON_NAME]: 'add-btn'}}>
                        {t('globalActions.addButton')}
                      </Button>
                    </Link>
                  </AccessControl>
                </PageTopActions>
              </Grid>
            </Grid>
          </PageStickyHeader>

          <Grid item xs={12} sx={{height: '500px'}} {...{[TestAttributes.TABLE_NAME]: 'table-results'}}>
            {isEntityListLoading ? (
              <Skeleton variant="rectangular" height={500} />
            ) : (
              <DataGridCustom
                tableColumns={tableColumns}
                rows={
                  campaigns?.map((campaign: CampaignListItem, index: number) => {
                    return {
                      ...campaign,
                      created_at: formatDateTime(campaign.created_at),
                      end_timestamp: formatDateTime(campaign.end_timestamp),
                      start_timestamp: formatDateTime(campaign.start_timestamp),
                      actions: campaign.campaign_id,
                      id: index,
                    };
                  }) || []
                }
                userPermissions={userPermissions}
              />
            )}
            {selectedCampaign && (
              <Menu
                id="campaign-list-more-actions-menu"
                keepMounted
                anchorEl={actionsMenuAnchorEl}
                open={Boolean(actionsMenuAnchorEl)}
                onClose={handleMoreActionsMenuClose}
              >
                <MenuItem
                  id={'campaign-list-more-actions-menu-item-view'}
                  onClick={() => navigate(`/${campaignType}-campaigns/${selectedCampaign.campaign_id}`)}
                >
                  {t('shared.view')}
                </MenuItem>

                {selectedCampaign?.dialer_type === CampaignDialerType.POWER && (
                  <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.MODIFY]}>
                    <MenuItem
                      id={'campaign-list-more-actions-menu-item-change-status'}
                      onClick={() => changeCampaignStatus(selectedCampaign)}
                    >
                      {t('shared.change-status')}
                    </MenuItem>
                  </AccessControl>
                )}
                <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.COPY]}>
                  <MenuItem id={'campaign-list-more-actions-menu-item-copy'} onClick={() => onCopyCampaignSelected()}>
                    {t('shared.copy')}
                  </MenuItem>
                </AccessControl>
              </Menu>
            )}
          </Grid>
        </Grid>
      </Container>
      {selectedCampaign && (
        <CopyCampaignDialog
          isOpen={isCopyCampaignDialogOpen}
          handleClose={() => setIsCopyCampaignDialogOpen(false)}
          selectedCampaign={selectedCampaign}
          copyCampaign={copyCampaign}
        />
      )}
    </Box>
  );
}

export default CampaignListPage;
