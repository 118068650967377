import {Box, Grid} from '@mui/material';
import Container from '@mui/material/Container';
import {useCallback, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import PageHeadline from '../../components/PageHeadline';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {FeatureName} from '../../../paths';
import {PageStickyHeader} from '../PageStickyHeader';
import {PageTopActions} from '../PageTopActions';
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog';
import {FormBuilder} from '../../form/FormBuilder';
import {useFormAPI} from '../../form/FormAPI';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import {onRejectSubmit} from '../../form/errorHandler';
import {OnSubmitHandler} from '../../form/model';
import {DefaultResponse} from '../../services/model/DefaultResponse';
import {TestAttributes} from '../../TestAttributes';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {useNavigate} from 'react-router-dom';
import {difference} from '../../form/utils';
import {Skeleton} from '@mui/lab';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';
import FeatureNotFound from '../notFound/FeatureNotFound';
import {AIAgentTriggerList} from '../../model/AIAgentTrigger';

function AIAgentTriggerPage() {
  const {id} = useParams();
  const {useAxiosAICallAPI} = useAxiosContext();
  const {t} = useTranslation();
  const {setLoading} = useLoading();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const [isDeactivatedModalOpen, setIsDeactivatedModalOpen] = useState<boolean>(false);
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.AI_AGENT_TRIGGERS);
  const [trigger, setTrigger] = useState<DefaultResponse | null>(null);
  const formAPI = useFormAPI();
  const [isValid, setIsValid] = useState<boolean>(true);
  const navigate = useNavigate();

  const [, getTriggerList] = useAxiosAICallAPI<ResponseListWrapper<AIAgentTriggerList>>(
    {
      url: `/${FeatureName.AI_AGENT_TRIGGERS}`,
    },
    {manual: false}
  );
  const [{data: triggerData, loading: isTriggerLoading, error: hasTriggerError}, getTriggerData] =
    useAxiosAICallAPI<DefaultResponse>(`/${FeatureName.AI_AGENT_TRIGGERS}/${id}`, {manual: true});

  const [
    {error: hasPatchTriggerError, loading: isPatchTriggerLoading, response: patchTriggerResponse},
    patchTriggerData,
  ] = useAxiosAICallAPI<DefaultResponse>(
    {url: `/${FeatureName.AI_AGENT_TRIGGERS}/${id}`, method: 'PATCH', params: {formatted: true}},
    {manual: true}
  );

  const [{error: hasPostTriggerError, loading: isPostTriggerLoading, response: postTriggerResponse}, postTriggerData] =
    useAxiosAICallAPI<DefaultResponse>({url: `/${FeatureName.AI_AGENT_TRIGGERS}`, method: 'POST'}, {manual: true});

  const [
    {
      data: deleteTriggerData,
      loading: isDeleteTriggerLoading,
      response: deleteTriggerResponse,
      error: hasDeleteTriggerError,
    },
    deleteTrigger,
  ] = useAxiosAICallAPI<DefaultResponse>(
    {url: `/${FeatureName.AI_AGENT_TRIGGERS}/${id}`, method: 'delete'},
    {manual: true}
  );

  const onSubmit: OnSubmitHandler = useCallback(
    (formData: Partial<DefaultResponse>) => {
      if (id) {
        formData = difference(formData, trigger);
        patchTriggerData({
          data: formData,
        }).then(() => {
          getTriggerData();
        });
      } else {
        postTriggerData({
          data: formData,
        });
      }
    },
    [trigger]
  );

  useEffect(() => {
    const loading = isTriggerLoading || isDeleteTriggerLoading || isPostTriggerLoading || isPatchTriggerLoading;
    setLoading(loading, 'AIAgentTriggerPage');
  }, [isTriggerLoading, isDeleteTriggerLoading, isPostTriggerLoading, isPatchTriggerLoading]);

  useEffect(() => {
    if (deleteTriggerResponse?.status === 204) {
      getTriggerList().then(() => {
        NotificationService.getInstance().sendNotification('Success', NotificationType.SUCCESS);
        navigate('/' + FeatureName.AI_AGENT_TRIGGERS);
      });
    }
  }, [deleteTriggerData]);

  useEffect(() => {
    if (id) {
      getTriggerData();
    } else {
      setTrigger(null);
    }
  }, [id]);

  useEffect(() => {
    if (triggerData) {
      setTrigger(triggerData);
    } else if (hasTriggerError) {
      NotificationService.getInstance().sendNotification(
        hasTriggerError?.response?.data?.message,
        NotificationType.ERROR
      );
      setIsValid(false);
    }
  }, [triggerData, hasTriggerError]);

  useEffect(() => {
    if (postTriggerResponse?.status === 201) {
      getTriggerList().then(() => {
        NotificationService.getInstance().sendNotification(
          postTriggerResponse.data?.['message'],
          NotificationType.SUCCESS
        );
        navigate(`/${FeatureName.AI_AGENT_TRIGGERS}/${postTriggerResponse.data['id']}`);
      });
    }
  }, [postTriggerResponse]);

  useEffect(() => {
    if (patchTriggerResponse?.status === 200) {
      getTriggerList().then(() => {
        NotificationService.getInstance().sendNotification(
          patchTriggerResponse.data?.['message'],
          NotificationType.SUCCESS
        );
      });
    }
  }, [patchTriggerResponse]);

  useEffect(() => {
    if (hasPostTriggerError) {
      NotificationService.getInstance().sendNotification(
        hasPostTriggerError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasPostTriggerError]);

  useEffect(() => {
    if (hasPatchTriggerError) {
      NotificationService.getInstance().sendNotification(
        hasPatchTriggerError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasPatchTriggerError]);

  useEffect(() => {
    if (hasDeleteTriggerError) {
      NotificationService.getInstance().sendNotification(
        hasDeleteTriggerError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasDeleteTriggerError]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        {isValid ? (
          <Grid container spacing={3}>
            <PageStickyHeader>
              <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
                <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                  <PageHeadline>{t('ai-agent-triggers.headline')}</PageHeadline>
                </Grid>
                <Grid item xs={12} md={6}>
                  <PageTopActions>
                    {id && (
                      <AccessControl
                        userPermissions={userPermissions}
                        allowedPermissions={[UserPermissions.DEACTIVATE_FEATURE_ITEM]}
                      >
                        <ConfirmationDialog
                          message={t('ai-agent-triggers.deactivate-message')}
                          headline={t('ai-agent-triggers.deactivate')}
                          isDialogOpen={isDeactivatedModalOpen}
                          handleClose={() => setIsDeactivatedModalOpen(false)}
                        >
                          <Button
                            onClick={() => {
                              deleteTrigger();
                              setIsDeactivatedModalOpen(false);
                            }}
                            {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-action-btn'}}
                            id="confirmation-dialog-action-btn"
                          >
                            {t('ai-agent-triggers.deactivate')}
                          </Button>
                        </ConfirmationDialog>

                        <Button
                          {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-btn'}}
                          id={'confirmation-dialog-btn'}
                          color="secondary"
                          onClick={() => setIsDeactivatedModalOpen(true)}
                        >
                          {t('ai-agent-triggers.deactivate')}
                        </Button>
                      </AccessControl>
                    )}

                    <Button
                      {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-btn'}}
                      id={'confirmation-dialog-btn'}
                      color="secondary"
                      onClick={() => setIsConfirmationDialogOpen(true)}
                    >
                      {t('shared.clear')}
                    </Button>

                    <AccessControl
                      userPermissions={userPermissions}
                      allowedPermissions={[id ? UserPermissions.MODIFY : UserPermissions.CREATE]}
                    >
                      <Button
                        {...{[TestAttributes.BUTTON_NAME]: 'save-btn'}}
                        id={'save-btn'}
                        onClick={() => {
                          formAPI.submit();
                        }}
                      >
                        {id ? t('shared.update') : t('shared.save')}
                      </Button>
                    </AccessControl>
                  </PageTopActions>
                </Grid>
              </Grid>
            </PageStickyHeader>
            <Grid item xs={12}>
              {isTriggerLoading ? (
                <Skeleton variant="rectangular" height={500} />
              ) : (
                <FormBuilder
                  onRejectSubmit={onRejectSubmit}
                  formId={FeatureName.AI_AGENT_TRIGGERS}
                  api={formAPI}
                  initialValues={trigger}
                  onSubmit={onSubmit}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <FeatureNotFound />
        )}
        <ConfirmationDialog
          message={t('shared.clear-form-modal-content')}
          headline={t('shared.clear-form-modal-headline')}
          isDialogOpen={isConfirmationDialogOpen}
          handleClose={() => setIsConfirmationDialogOpen(false)}
        >
          <Button
            onClick={() => {
              formAPI.reset();
              setIsConfirmationDialogOpen(false);
            }}
            {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-action-btn'}}
            id="confirmation-dialog-action-btn"
          >
            {t('shared.accept')}
          </Button>
        </ConfirmationDialog>
      </Container>
    </Box>
  );
}

export default AIAgentTriggerPage;
