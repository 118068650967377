import {Fragment, useState} from 'react';
import List from '@mui/material/List';
import {Collapse} from '@mui/material';
import {NavItem} from './NavItem';
import {SpartanMenuItem} from '../model/SpartanMenuItems';
interface CollapsableNavItemProps {
  isSideNavOpen: boolean;
  title: string;
  icon?: string;
  tooltip: string;
  children: Array<SpartanMenuItem>;
  item_id: string;
  key: number;
}
export default function CollapsableNavItem({
  isSideNavOpen,
  item_id,
  title,
  icon,
  tooltip,
  children,
}: CollapsableNavItemProps) {
  const [openCollapse, setOpenCollapse] = useState<boolean>(true);
  const handleCollapseStatus = () => {
    setOpenCollapse(!openCollapse);
  };

  return (
    <Fragment>
      <NavItem
        key={item_id}
        title={title}
        icon={icon}
        onClick={handleCollapseStatus}
        isCollapse={openCollapse}
        tooltip={tooltip}
        hasChildren={true}
        href="#"
      />

      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{marginLeft: isSideNavOpen ? {xs: 0, md: '30px'} : undefined}}>
          {children.map((item: SpartanMenuItem) => (
            <NavItem
              href={item.action}
              key={item.menu_item_id}
              title={item.label}
              icon={item.icon}
              isCollapse={openCollapse}
              actionMessage={item.action_message}
              tooltip={item.tooltip}
              hasChildren={false}
            />
          ))}
        </List>
      </Collapse>
    </Fragment>
  );
}
