import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import {Container, Grid, IconButton, Skeleton, Typography, useTheme} from '@mui/material';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import {GridRowModel} from '@mui/x-data-grid-pro';
import {useAxiosContext} from '../../../context/AxiosContext';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import {useParams} from 'react-router';
import {FeatureName} from '../../../../paths';
interface ResultProps {
  row: GridRowModel;
}
export default function QueryResultViewDialogAction({row}: ResultProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {featureId} = useParams();
  const audioSupportedFeatures = new Set([FeatureName.AI_CALL_LOG.toString()]);
  const audioUrlByFeature = {
    [FeatureName.AI_CALL_LOG]: `/call/${row['ai call id']}/recording`,
  };

  const toggleDialog = () => {
    setIsOpen(!isOpen);
  };
  const {useAxiosAICallAPI} = useAxiosContext();

  const [{data: audioData, loading: isAudioLoading}, getTriggerData] = useAxiosAICallAPI<MediaSource>(
    {url: featureId && audioUrlByFeature[featureId], responseType: 'blob'},
    {
      manual: true,
    }
  );

  const [auidioURL, setAudioURL] = useState<null | string>(null);

  useEffect(() => {
    if (isOpen && featureId && audioSupportedFeatures.has(featureId) && !auidioURL) getTriggerData();
  }, [isOpen]);
  useEffect(() => {
    if (audioData) {
      const blobUrl = URL.createObjectURL(audioData);
      setAudioURL(blobUrl);
    }
  }, [audioData]);

  return (
    <>
      <IconButton size="large" edge={false} onClick={toggleDialog}>
        <PreviewOutlinedIcon sx={{width: '100%'}} />
      </IconButton>
      <Dialog onClose={toggleDialog} open={isOpen} fullWidth={true} maxWidth={'md'}>
        <DialogTitle>
          <Typography variant="h4">Overview</Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={toggleDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{padding: 0}}>
          <Grid container>
            {Object.entries(row).map(([key, value], index) => {
              return (
                <>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    className={index % 2 ? 'overview-dialog-row-odd' : 'overview-dialog-row-even'}
                    sx={{
                      paddingInline: '25px',
                      paddingBlock: '14px',
                    }}
                  >
                    <Typography component="span" sx={{fontWeight: '500'}}>
                      {key}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    sx={{paddingInline: '25px', paddingBlock: '14px'}}
                    className={index % 2 ? 'overview-dialog-row-odd-light' : 'overview-dialog-row-even-light'}
                  >
                    <Typography sx={{whiteSpace: 'pre-wrap'}}>{value !== undefined && value.toString()}</Typography>
                  </Grid>
                </>
              );
            })}
          </Grid>
          {featureId && audioSupportedFeatures.has(featureId) && (
            <Container
              sx={{
                position: 'sticky',
                bottom: 0,
                boxShadow: 'none',
                fontFamily: 'Quicksand',
                height: '110px',
                padding: '10px',
                backgroundColor: 'background.default',
              }}
              disableGutters={true}
            >
              {isAudioLoading ? (
                <Skeleton variant="rectangular" height={'88px'} />
              ) : auidioURL ? (
                <AudioPlayer src={auidioURL} />
              ) : (
                <Typography color={'rgba(134, 134, 134, 1)'} sx={{paddingBlock: '25px'}} align="center" variant="h6">
                  The AI Call recording is not available.
                </Typography>
              )}
            </Container>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
