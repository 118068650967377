import {useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useAuth0} from '@auth0/auth0-react';
import moment from 'moment/moment';
import {Box, Button, Container, Grid, Skeleton, Typography} from '@mui/material';
import {FormBuilder} from '../../form/FormBuilder';
import PageHeadline from '../../components/PageHeadline';
import AccessControl, {checkPermissions, UserPermissions} from '../../components/shared/AccessControl';
import {FeatureName} from '../../../paths';
import {Campaign} from '../../model/Campaign';
import {Auth0User, getUserEmail, getUserId, isPowerOrg} from '../../model/Auth0User';
import {Status} from '../../model/Feature';
import {difference} from '../../form/utils';
import {PageTopActions} from '../PageTopActions';
import {PageStickyHeader} from '../PageStickyHeader';
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog';
import {BaseFormFieldSchemaDecorator} from '../../form/logic/FormDecorator';
import {formatDateTime} from '../../utils/DateUtils';
import {FormFieldMultipleCondition, FormFieldSingleCondition} from '../../form/logic/conditions/Condition';
import {FormConfig} from '../../form/FormConfig';
import {useFormAPI} from '../../form/FormAPI';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import {onRejectSubmit} from '../../form/errorHandler';
import {ResponseFile} from '../../model/Files';
import {TestAttributes} from '../../TestAttributes';
import {useSettings} from '../UseSettings';
import {StringKeyObject, useStateSubject} from '../../form/state/FormState';
import BucketDialog from './dialogs/BucketDialog';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {useFiles} from '../../UseFiles';
import {AxiosResponse} from 'axios';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';
import useAwsBucket from '../../hooks/useAwsBucket';
import {useConfig} from '../../context/ConfigContext';
import FeatureNotFound from '../notFound/FeatureNotFound';

function CampaignPage() {
  const {useAxiosBFF} = useAxiosContext();
  const {t} = useTranslation();
  const {id} = useParams();
  const {settings} = useSettings();
  const auth0 = useAuth0<Auth0User>();
  const {user} = auth0;
  const {campaignType} = useParams();

  const power = isPowerOrg(user);

  const {FORCE_UPDATE_CAMPAIGN_MIN_COUNT, FORCE_UPDATE_CAMPAIGN_TIMEOUT_DELAY} = useConfig();
  const navigate = useNavigate();
  const [isDeactivatedModalOpen, setIsDeactivatedModalOpen] = useState<boolean>(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const [isBucketDialogOpen, setIsBucketDialogOpen] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>();
  const [formConfig, setFormConfig] = useState<FormConfig>();
  const [isForceUpdateModalOpen, setIsForceUpdateModalOpen] = useState<boolean>(false);
  const [inactiveCounter, setInactiveCounter] = useState<number>(0);
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.CAMPAIGNS);
  const [isValid, setIsValid] = useState<boolean>(true);
  const formAPI = useFormAPI();
  const $state = useStateSubject();
  const [formStateValues, setFormStateValues] = useState<StringKeyObject<any>>({});
  const {postFiles} = useFiles();
  const {setLoading} = useLoading();
  const awsBucket = useAwsBucket();

  const [{data: campaign, loading: isCampaignLoading, error: getCampaignError}, getCampaignData] =
    useAxiosBFF<Campaign>(`/${FeatureName.CAMPAIGNS}/${id}`, {manual: true});

  const featureName = FeatureName[`${campaignType}_campaigns`.replaceAll('-', '_').toUpperCase()];
  const [
    {loading: isCampaignPatchLoading, error: patchCampaignError, response: patchCampaignResponse},
    patchCampaignData,
  ] = useAxiosBFF<Campaign>(
    {url: `/${FeatureName.CAMPAIGNS}/${id}`, method: 'PATCH', params: {formatted: true}},
    {manual: true}
  );

  const [
    {loading: postCampaignResponseLoading, error: postCampaignError, response: postCampaignResponse},
    postCampaignData,
  ] = useAxiosBFF<Campaign>({url: `/${FeatureName.CAMPAIGNS}`, method: 'POST'}, {manual: true});

  const [
    {loading: deleteCampaignLoading, error: deleteCampaignError, response: deleteCampaignResponse},
    deleteCampaignData,
  ] = useAxiosBFF<Campaign>({url: `/${FeatureName.CAMPAIGNS}/${id}`, method: 'DELETE'}, {manual: true});

  useEffect(() => {
    const sub = $state.subscribe((next) => setFormStateValues(next.values));
    return () => sub.unsubscribe();
  }, [$state]);

  useEffect(() => {
    const loading = postCampaignResponseLoading || deleteCampaignLoading || isCampaignLoading || isCampaignPatchLoading;
    setLoading(loading, 'CampaignPage');
  }, [postCampaignResponseLoading, isCampaignPatchLoading, isCampaignLoading, deleteCampaignLoading]);

  useEffect(() => {
    if (postCampaignResponse?.status === 201) {
      NotificationService.getInstance().sendNotification(
        postCampaignResponse.data?.['message'],
        NotificationType.SUCCESS
      );
      navigate(`/${campaignType}-campaigns/${postCampaignResponse.data['id']}`);
    }
  }, [postCampaignResponse]);

  useEffect(() => {
    if (patchCampaignResponse?.status === 200) {
      NotificationService.getInstance().sendNotification(
        patchCampaignResponse.data?.['message'],
        NotificationType.SUCCESS
      );
      getCampaignData();
    }
  }, [patchCampaignResponse]);

  useEffect(() => {
    if (deleteCampaignResponse) {
      NotificationService.getInstance().sendNotification(
        deleteCampaignResponse.data?.['message'],
        NotificationType.SUCCESS
      );
      navigate(`/${campaignType}-campaigns`);
    }
  }, [deleteCampaignResponse]);

  useEffect(() => {
    if (deleteCampaignError) {
      NotificationService.getInstance().sendNotification(
        deleteCampaignError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [deleteCampaignError]);

  useEffect(() => {
    if (postCampaignError) {
      NotificationService.getInstance().sendNotification(
        postCampaignError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [postCampaignError]);

  useEffect(() => {
    if (getCampaignError) {
      NotificationService.getInstance().sendNotification(
        getCampaignError?.response?.data?.message,
        NotificationType.ERROR
      );
      setIsValid(false);
    }
  }, [getCampaignError]);
  useEffect(() => {
    const canForceUpdate = checkPermissions(userPermissions || [], [UserPermissions.FORCE_UPDATE_CAMPAIGN]);
    if (patchCampaignError) {
      NotificationService.getInstance().sendNotification(
        patchCampaignError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
    if (patchCampaignError?.response?.status === 403 && canForceUpdate) {
      setInactiveCounter(inactiveCounter + 1);
    }
  }, [patchCampaignError]);

  useEffect(() => {
    if (id) {
      getCampaignData();
    } else {
      setInitialValues(null);
    }
  }, [id]);

  useEffect(() => {
    if (campaign) {
      setInitialValues(campaign);
    } else if (settings) {
      setInitialValues({
        start_timestamp: formatDateTime(moment(settings?.office_start_hour, 'H')),
        end_timestamp: formatDateTime(moment(settings?.office_end_hour, 'H')),
      });
    }
  }, [campaign, settings]);

  useEffect(() => {
    setFormConfig({...formConfig, $state: $state});
  }, [$state]);

  useEffect(() => {
    const decorators = [
      new BaseFormFieldSchemaDecorator((field) => {
        if (field.field_name !== 'status_campaign') {
          const condition = {
            type: 'single_condition',
            field_name: 'status_campaign',
            operator: 'equal',
            value: Status.ACTIVE,
          } as FormFieldSingleCondition;
          if (field.disabled !== undefined) {
            field.disabled = {
              type: 'multiple_condition',
              operator: 'or',
              value: [field.disabled, condition],
            } as FormFieldMultipleCondition;
          } else {
            field.disabled = condition;
          }
        }
      }),
      new BaseFormFieldSchemaDecorator((field) => {
        if (field.field_name === 'end_timestamp') {
          field.validations = field.validations || [];
          field.validations.push({
            message: 'must be greater than start date',
            condition: {
              type: 'single_condition',
              field_name: 'start_timestamp',
              operator: 'less_than_relative',
              value: 'end_timestamp',
            } as FormFieldSingleCondition,
          });
        }
      }),
    ];

    decorators.push(
      new BaseFormFieldSchemaDecorator((field) => {
        if (field.field_name === 'end_timestamp') {
          field.validations = field.validations || [];
          field.validations.push({
            message: 'power campaigns cannot last more than one day',
            condition: {
              type: 'single_condition',
              field_name: 'start_timestamp',
              operator: 'eval',
              value: 'toEpoch(values.start_timestamp) + 86400 >= toEpoch(values.end_timestamp)',
            } as FormFieldSingleCondition,
          });
        }
      })
    );
    setFormConfig({...formConfig, decorators: decorators, $state: $state});
  }, [power]);

  useEffect(() => {
    if (inactiveCounter >= FORCE_UPDATE_CAMPAIGN_MIN_COUNT) {
      setIsForceUpdateModalOpen(true);
    }
    if (inactiveCounter) {
      setTimeout(() => setInactiveCounter(inactiveCounter - 1), FORCE_UPDATE_CAMPAIGN_TIMEOUT_DELAY);
    }
  }, [inactiveCounter]);

  /**
   * When creating a Campaign...
   * For some reason the files are uploaded first, followed by the campaign POST.
   * The upload returns the campaign_id which is used in BE to define the folder = f"{folder}/{campaign_id}/theFile"
   * This campaign_id is expected in the campaign POST
   * It would be better to create the campaign first and upload to the campaign
   * **/

  const onSubmit = useCallback(
    async (formData: Partial<Campaign>) => {
      if (id) {
        formData = difference(formData, campaign);
        patchCampaignData({
          data: formData,
        });
      } else {
        const uploadResponse = await uploadCampaignFiles(formData).then(
          (response: AxiosResponse<ResponseFile> | null) => {
            return response;
          }
        );
        if (uploadResponse?.status === 201 && uploadResponse.data?.campaign_id) {
          const campaignId = uploadResponse.data?.campaign_id;
          formData['campaign_id'] = campaignId;
          formData['email_notification'] = getUserEmail(auth0.user) || '';
          formData['filename'] = buildCampaignFilePath(campaignId, formData?.['filename']?.['name']);
        }
        postCampaignData({
          data: {...formData, status: 'active'},
        });
      }
    },
    [campaign]
  );

  const onForceSubmit = useCallback(
    async (formData: Partial<Campaign>) => {
      formData = difference(formData, campaign);
      patchCampaignData({
        data: formData,
        params: {formatted: true, force: true},
      });
    },
    [campaign]
  );

  function buildCampaignFilePath(campaignId: string, fileName: string): string {
    return `/${awsBucket}/${FeatureName.CAMPAIGNS}/${campaignId}/${fileName}`;
  }

  function uploadCampaignFiles(data: any): Promise<AxiosResponse<ResponseFile> | null> {
    if (!data?.filename) {
      return Promise.resolve(null);
    }

    const postFilesBody = new FormData();
    const email = getUserEmail(auth0.user) || '';
    const fileToUpload = data?.filename;
    postFilesBody.append('file1', fileToUpload);
    postFilesBody.append('folder', FeatureName.CAMPAIGNS);
    postFilesBody.append('user_id', getUserId(auth0.user) || '');
    postFilesBody.append('email', email);
    postFilesBody.append('source', FeatureName.CAMPAIGNS);
    return postFiles(postFilesBody);
  }

  function handleDeactivateCampaign() {
    if (id) {
      deleteCampaignData();
    }
  }

  function handleForceUpdate() {
    formAPI.submit();
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        {isValid ? (
          <Grid container spacing={3}>
            <PageStickyHeader>
              <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
                <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                  <PageHeadline>{t(`campaign.headline.${campaignType}`)}</PageHeadline>
                </Grid>
                <Grid item xs={12} md={6}>
                  <PageTopActions>
                    {(formStateValues.power_call_local_presence_bucket ||
                      formStateValues.preview_call_local_presence_bucket) && (
                      <Button
                        id={'view-dids-btn'}
                        {...{[TestAttributes.BUTTON_NAME]: 'view-dids-btn'}}
                        color="secondary"
                        onClick={() => {
                          setIsBucketDialogOpen(true);
                        }}
                      >
                        {t('shared.view-dids')}
                      </Button>
                    )}
                    {id && (
                      <AccessControl
                        userPermissions={userPermissions}
                        allowedPermissions={[UserPermissions.DEACTIVATE_FEATURE_ITEM]}
                      >
                        <ConfirmationDialog
                          message={t('campaign.deactivate-message')}
                          headline={t('campaign.deactivate')}
                          isDialogOpen={isDeactivatedModalOpen}
                          handleClose={() => setIsDeactivatedModalOpen(false)}
                        >
                          <Button
                            onClick={() => {
                              handleDeactivateCampaign();
                              setIsDeactivatedModalOpen(false);
                            }}
                            {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-action-btn'}}
                            id="confirmation-dialog-action-btn"
                          >
                            {t('campaign.deactivate')}
                          </Button>
                        </ConfirmationDialog>

                        <Button
                          id={'confirmation-dialog-btn'}
                          {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-btn'}}
                          color="secondary"
                          onClick={() => setIsDeactivatedModalOpen(true)}
                        >
                          {t('campaign.deactivate')}
                        </Button>
                      </AccessControl>
                    )}
                    <Button
                      id={'confirmation-dialog-btn'}
                      {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-btn'}}
                      color="secondary"
                      onClick={() => setIsConfirmationDialogOpen(true)}
                    >
                      {t('shared.clear')}
                    </Button>
                    <AccessControl
                      userPermissions={userPermissions}
                      allowedPermissions={[id ? UserPermissions.MODIFY : UserPermissions.CREATE]}
                    >
                      <Button
                        id={'save-btn'}
                        {...{[TestAttributes.BUTTON_NAME]: 'save-btn'}}
                        onClick={() => formAPI.submit()}
                      >
                        {id ? t('shared.update') : t('shared.save')}
                      </Button>
                    </AccessControl>
                  </PageTopActions>

                  {campaign?.status_campaign === Status.ACTIVE && (
                    <Typography sx={{mt: (theme) => theme.spacing(1), textAlign: 'right'}}>
                      {t('campaign.active-campaign-message')}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </PageStickyHeader>

            <Grid item xs={12}>
              {isCampaignLoading ? (
                <Skeleton variant="rectangular" height={500} />
              ) : (
                featureName && (
                  <FormBuilder
                    formId={featureName}
                    api={formAPI}
                    onSubmit={isForceUpdateModalOpen ? onForceSubmit : onSubmit}
                    initialValues={initialValues}
                    config={formConfig}
                    onRejectSubmit={onRejectSubmit}
                  />
                )
              )}
            </Grid>
          </Grid>
        ) : (
          <FeatureNotFound />
        )}
        <ConfirmationDialog
          message={t('shared.clear-form-modal-content')}
          headline={t('shared.clear-form-modal-headline')}
          isDialogOpen={isConfirmationDialogOpen}
          handleClose={() => setIsConfirmationDialogOpen(false)}
        >
          <Button
            onClick={() => {
              formAPI.reset();
              setIsConfirmationDialogOpen(false);
            }}
            id="confirmation-dialog-action-btn"
            {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-action-btn'}}
          >
            {t('shared.accept')}
          </Button>
        </ConfirmationDialog>
        <BucketDialog
          bucketName={
            formStateValues.power_call_local_presence_bucket || formStateValues.preview_call_local_presence_bucket
          }
          isDialogOpen={isBucketDialogOpen}
          handleClose={() => setIsBucketDialogOpen(false)}
          localPresence={true}
        ></BucketDialog>
        <ConfirmationDialog
          message={t('campaign.force-inactive-update.message')}
          headline={t('campaign.force-inactive-update.headline')}
          isDialogOpen={isForceUpdateModalOpen}
          handleClose={() => setIsForceUpdateModalOpen(false)}
        >
          <Button
            onClick={() => {
              handleForceUpdate();
              setIsForceUpdateModalOpen(false);
            }}
            {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-action-btn'}}
            id="confirmation-dialog-action-btn"
          >
            {t('shared.accept')}
          </Button>
        </ConfirmationDialog>
      </Container>
    </Box>
  );
}

export default CampaignPage;
