import {useEffect, useState} from 'react';
import {FormProvider, useFieldArray, useForm, useWatch} from 'react-hook-form';
import {Link, useNavigate} from 'react-router-dom';
import {useParams} from 'react-router';
import {useTranslation} from 'react-i18next';
import {useAuth0} from '@auth0/auth0-react';
import {isEmpty, omit, pick} from 'lodash';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {GridCellParams, GridColDef} from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {PageTopActions} from '../PageTopActions';
import VirtualFieldList from './components/VirtualFieldList';
import {FormFieldType} from '../../form/logic/FormSchema';
import {ConditionOperator, LogicOperator} from './ConditionOperator';
import PageHeadline from '../../components/PageHeadline';
import QueryCondition from './components/QueryCondition';
import {
  Condition,
  FormDataType,
  Query,
  QueryDetail,
  QueryDetailLead,
  QueryFieldSchema,
  QueryFormSchemaResponseWrapper,
  QuerySaved,
  QueryToExport,
} from '../../model/Query';
import QueryContextProvider from '../../context/QueryContextProvider';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {FormBuilder} from '../../form/FormBuilder';
import {Auth0User, getUserEmail} from '../../model/Auth0User';
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog';
import {PageStickyHeader} from '../PageStickyHeader';
import {PaginatedResponse} from '../../services/model/PaginatedResponse';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {useStateSubject} from '../../form/state/FormState';
import {FormConfig} from '../../form/FormConfig';
import {useFormAPI} from '../../form/FormAPI';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import {isQueryLastConditionValid} from './utils/queryValidationMethods';
import {onRejectSubmit} from '../../form/errorHandler';
import QueryFieldsSortable from './components/QueryFieldsSortable';
import DataGridCustom from '../../components/DataGridCustom';
import QueryService, {toQueryDetails} from '../../services/QueryService';
import {difference} from '../../form/utils';
import {TestAttributes} from '../../TestAttributes';
import {FeatureName} from '../../../paths';
import {DynamicOptionsService} from '../../services/DynamicOptionsService';
import FieldsApiClient from '../../services/api/FieldsApiClient';
import {ColumnSizes} from '../../components/shared/ColumnSize';
import {Status} from '../../model/Feature';
import {LeadListItem} from '../../model/Lead';
import {DefaultResponse} from '../../services/model/DefaultResponse';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';
import QueryTotalResult from './components/QueryTotalResults';
import FeatureNotFound from '../notFound/FeatureNotFound';
import UserService from '../../services/UserService';
import QueryResultViewDialogAction from './components/QueryResultViewDialogAction';
import {GridRowModel} from '@mui/x-data-grid-pro';

const default_columns: GridColDef[] = [
  {field: 'created_at', headerName: 'created at', width: ColumnSizes.MD},
  {field: 'created_by', headerName: 'created by', width: ColumnSizes.XL},
  {field: 'last_updated_at', headerName: 'last updated at', width: ColumnSizes.MD},
  {field: 'last_updated_by', headerName: 'last updated by', width: ColumnSizes.XL},
];
export function buildTableColumns(
  query: QuerySaved,
  allFields: Array<QueryFieldSchema> | null,
  featureName: FeatureName,
  userPermissions: UserPermissions[]
): GridColDef[] {
  let columns: GridColDef[] = [...default_columns];

  if (query?.query_fields?.length) {
    columns = query.query_fields?.map((field_name: string) => {
      const field_schema = allFields?.find((field_schema) => field_schema.field_name === field_name);
      const field = field_name;
      const headerName = field_schema?.label || field_name;
      const width = ColumnSizes.LG;
      return {field, headerName, width};
    });
  }

  const actionColumn = buildActionColumn(userPermissions, featureName, columns);
  if (actionColumn) columns.push(actionColumn);
  return columns;
}

function buildActionColumn(
  userPermissions: UserPermissions[],
  featureName: string,
  columns: GridColDef[]
): GridColDef | undefined {
  const shouldRedirect: boolean = userPermissions.includes(UserPermissions.VIEW_TABLE_ACTION_COLUMN);
  const shouldDisplayOverview: boolean = userPermissions.includes(UserPermissions.VIEW_TABLE_OVERVIEW_COLUMN);
  let renderCell;

  if (shouldRedirect) {
    renderCell = (params: GridCellParams) => {
      return (
        <Link to={`/${featureName}/${params.value}`}>
          <IconButton size="large">
            <VisibilityIcon />
          </IconButton>
        </Link>
      );
    };
  } else if (shouldDisplayOverview) {
    renderCell = (params: GridCellParams) => {
      const rowData = formatRawColumnData(params.row, columns);
      return <QueryResultViewDialogAction row={rowData} />;
    };
  }

  let actionColumn;
  if (renderCell) {
    actionColumn = {
      field: 'actions',
      filterable: false,
      disableExport: true,
      headerName: 'actions',
      width: ColumnSizes.MD,
      renderCell: renderCell,
    };
  }
  return actionColumn;
}

function formatRawColumnData(rowData: GridRowModel, columns: GridColDef[]): GridRowModel {
  const formattedData = {};
  columns.forEach((column) => {
    const value = rowData[column.field];
    if (column.field !== 'actions') {
      formattedData[column.headerName || column.field] = value;
    }
  });
  return formattedData;
}

function QueryBuilder() {
  const {useAxiosBFF} = useAxiosContext();
  const {t} = useTranslation();
  const {id, featureId} = useParams();
  const {setLoading} = useLoading();
  const navigate = useNavigate();
  const auth0 = useAuth0<Auth0User>();

  const featureNameKey = (featureId ?? '').toUpperCase().replaceAll('-', '_');
  const queryFormFeatureNameKey = `${featureNameKey}_QUERIES`;
  const [featureName, setFeatureName] = useState<FeatureName>(FeatureName[featureNameKey]);
  const [queryFormFeatureName, setQueryFormFeatureName] = useState<FeatureName>(FeatureName[queryFormFeatureNameKey]);
  const [allFields, setAllFields] = useState<Array<QueryFieldSchema> | null>(null);
  const [conditionFields, setConditionFields] = useState<Array<QueryFieldSchema>>([]);
  const [filteredFields, setFilteredFields] = useState<Array<QueryFieldSchema>>([]);
  const [searchFieldQuery, setSearchFieldQuery] = useState<string>('');
  const [queryResultColumns, setQueryResultColumns] = useState<GridColDef[]>([]);
  const {userPermissions}: UsePermissionState = usePermissions(queryFormFeatureName);
  const [query, setQuery] = useState<QuerySaved | null>(null);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const [isDeactivatedModalOpen, setIsDeactivatedModalOpen] = useState<boolean>(false);
  const [config, setConfig] = useState<FormConfig>();
  const [isValidId, setIsValidId] = useState<boolean>(true);
  const [listHeight, setListHeight] = useState<number>(400);
  const [expanded, setExpanded] = useState<boolean>(true);
  const $state = useStateSubject();

  const [userOperationId, setUserOperationId] = useState<string>();
  const queryCondition = 'query_conditions';
  const queryFields = 'query_fields';
  const order = 'order';
  const orderBy = 'order_by';
  enum OwnershipFields {
    OPERATION = 'operation',
    OPERATION_MODALITY = 'operation_modality',
    OWNERSHIP_TYPE = 'ownership_type',
    TEAM_OWNERSHIP = 'team_ownership',
    USER_OWNERSHIP = 'user_ownership',
  }

  const [page, setPage] = useState<number>(0);
  const [pageToRequest, setPageToRequest] = useState<number>(1);
  const [totalResults, setTotalResults] = useState<number | null>(null);
  const [queryResult, setQueryResult] = useState<Array<QueryDetailLead>>([]);
  const [count, setCount] = useState<number>(0);
  const [trueTime, setTrueTime] = useState<string>();
  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [defaultQueryFields, setDefaultQueryFields] = useState<Array<QueryFieldSchema>>();
  const [defaultValues, setDefaultValues] = useState<Query>();
  const [pageCount, setPageCount] = useState<number>(0);
  const [isLoadingResults, setIsLoadingResults] = useState<boolean>(false);
  const defaultConditionFieldName = [FeatureName.AI_CALL_LOG, FeatureName.RVM_LOG].includes(featureId as FeatureName)
    ? 'operation_id'
    : 'status';

  const pageSize: number = 100;
  const methods = useForm<Query>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {reset, control, getValues, formState} = methods;
  const {fields, append, remove, insert, swap} = useFieldArray({
    control,
    name: 'query_conditions',
  });
  const formAPI = useFormAPI();
  const {isValid, isDirty} = formState;
  const featuresWithDefaultConditions = [FeatureName.LEADS, FeatureName.AI_CALL_LOG, FeatureName.RVM_LOG];
  const lastCondition = featuresWithDefaultConditions.includes(featureName) ? fields.length - 2 : fields.length - 1;

  const queryConditions = getValues('query_conditions');
  const watchConditions = useWatch({control, name: `query_conditions`});
  const [{data: queryDetailResponse, error: getQueryDetailError, loading: isQueryDetailLoading}, getQueryDetail] =
    useAxiosBFF<QueryDetail>(`/${FeatureName.QUERIES}/${id}`, {manual: true});
  const [{error: getMetadataForQueriesError}, getMetadataForQueries] = useAxiosBFF<QueryFormSchemaResponseWrapper>(
    {
      url: `/${FeatureName.QUERIES}`,
      method: 'GET',
      params: {metadata_from: FeatureName[featureNameKey]},
    },
    {manual: true}
  );

  const [{error: runQueryError, loading: isRunEntityLoading}, runQuery] = useAxiosBFF<PaginatedResponse<LeadListItem>>(
    {url: `/${FeatureName.QUERIES}`, method: 'POST'},
    {manual: true}
  );
  const [{error: exportQueryError, loading: isExportQueryLoading}, exportQuery] = useAxiosBFF<
    PaginatedResponse<LeadListItem>
  >({url: `/${FeatureName.QUERIES}`, method: 'POST'}, {manual: true});
  const [{error: postQueryError, loading: isPostEntityLoading}, postQuery] = useAxiosBFF<
    PaginatedResponse<QueryDetail>
  >({url: `/${FeatureName.QUERIES}`, method: 'POST'}, {manual: true});
  const [{error: patchQueryError, loading: isPatchEntityLoading}, patchQuery] = useAxiosBFF<QueryDetail>(
    {url: `/${FeatureName.QUERIES}/${id}`, method: 'PATCH', params: {formatted: true}},
    {manual: true}
  );
  const [{response: deleteQueryResponse, error: deleteCampaignError, loading: isDeleteEntityLoading}, deleteQueryData] =
    useAxiosBFF<DefaultResponse>({url: `/${FeatureName.QUERIES}/${id}`, method: 'DELETE'}, {manual: true});
  useEffect(() => {
    const loading =
      isDeleteEntityLoading ||
      isPatchEntityLoading ||
      isPostEntityLoading ||
      isRunEntityLoading ||
      isQueryDetailLoading ||
      isExportQueryLoading;
    setLoading(loading, 'QueryBuilder');
  }, [
    isDeleteEntityLoading,
    isPatchEntityLoading,
    isPostEntityLoading,
    isRunEntityLoading,
    isExportQueryLoading,
    isQueryDetailLoading,
  ]);
  useEffect(() => {
    if (exportQueryError) {
      NotificationService.getInstance().sendNotification(
        exportQueryError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [exportQueryError]);

  useEffect(() => {
    if (postQueryError) {
      NotificationService.getInstance().sendNotification(
        postQueryError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [postQueryError]);
  useEffect(() => {
    if (patchQueryError) {
      NotificationService.getInstance().sendNotification(
        patchQueryError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [patchQueryError]);

  useEffect(() => {
    if (deleteCampaignError) {
      NotificationService.getInstance().sendNotification(
        deleteCampaignError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [deleteCampaignError]);
  useEffect(() => {
    if (queryDetailResponse) {
      const queryDetail = toQueryDetails(queryDetailResponse);
      setQuery(queryDetail?.query);
    }
  }, [queryDetailResponse]);

  useEffect(() => {
    if (runQueryError) {
      NotificationService.getInstance().sendNotification(
        runQueryError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [runQueryError]);
  useEffect(() => {
    if (getQueryDetailError) {
      NotificationService.getInstance().sendNotification(
        getQueryDetailError?.response?.data?.message,
        NotificationType.ERROR
      );
      setIsValidId(false);
    }
  }, [getQueryDetailError]);
  useEffect(() => {
    if (getMetadataForQueriesError) {
      NotificationService.getInstance().sendNotification(
        getMetadataForQueriesError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [getMetadataForQueriesError]);

  useEffect(() => {
    const defaultValue = query && id ? query : defaultValues;
    reset(defaultValue);
  }, [reset, query, defaultValues]);

  useEffect(() => {
    const sub = UserService.getInstance()
      .get()
      .subscribe((_user) => {
        if (_user?.operation_id) {
          setUserOperationId(_user.operation_id);
        }
      });
    return () => sub.unsubscribe();
  }, []);

  function getStringArray(defaultQueryFields: QueryFieldSchema[]) {
    return defaultQueryFields.map((col: QueryFieldSchema) => col.field_name);
  }

  function getDefaultField(allFields: Array<QueryFieldSchema> | null, fieldName: string) {
    return allFields?.find((field: QueryFieldSchema) => field.field_name === fieldName);
  }

  function initializeDefaultValues(defaultQueryFields: QueryFieldSchema[], allFields: Array<QueryFieldSchema> | null) {
    const defaultField = getDefaultField(allFields, defaultConditionFieldName);
    const defaultValues: Query = {
      query_fields: getStringArray(defaultQueryFields),
      query_conditions: [],
    };

    if (defaultField && featureId === FeatureName.LEADS) {
      defaultValues.query_conditions.push({
        id: defaultField.field_id,
        field_id: defaultField.field_id,
        field_name: defaultField.field_name,
        field_type: FormFieldType.TEXT,
        data_type: FormDataType.TEXT,
        condition_type: ConditionOperator.EQUALS,
        values: [{value: Status.ACTIVE}],
      });
    } else if (defaultField && [FeatureName.AI_CALL_LOG, FeatureName.RVM_LOG].includes(featureId as FeatureName)) {
      const userOperationDefaultVal = userOperationId ? [{value: userOperationId}] : [{value: ''}];
      defaultValues.query_conditions.push({
        id: defaultField.field_id,
        field_id: defaultField.field_id,
        field_name: defaultField.field_name,
        field_type: FormFieldType.SELECT,
        data_type: FormDataType.TEXT,
        condition_type: ConditionOperator.EQUALS,
        values: userOperationDefaultVal,
      });
    }

    setDefaultValues(defaultValues);
  }

  useEffect(() => {
    setConfig({$state});
  }, [$state]);

  useEffect(() => {
    if (featureId) {
      setFeatureName(FeatureName[featureNameKey]);
    }
  }, [featureId]);

  useEffect(() => {
    if (featureName) {
      setQueryFormFeatureName(FeatureName[queryFormFeatureNameKey]);
      setSearchFieldQuery('');
    }
  }, [featureName]);

  useEffect(() => {
    getMetadataForQueries()
      .then((response: any) => {
        const data: QueryFormSchemaResponseWrapper = response?.data;
        if (data?.query_condition_fields) {
          return new DynamicOptionsService(FieldsApiClient.with(auth0))
            .resolveDynamicOptions(data?.query_condition_fields)
            .then((query_condition_fields) => ({...data, query_condition_fields}));
        }
        return data;
      })
      .then((response: QueryFormSchemaResponseWrapper) => {
        if (response?.query_display_fields) {
          const searchableFields = response?.query_display_fields;
          const conditionFields = response?.query_condition_fields;
          searchableFields?.forEach(
            (f) =>
              (f.label = t(
                `feature.${featureName}.field.${f.field_name}.query_display_field`,
                t(`feature.${featureName}.field.${f.field_name}`, f.label)
              ))
          );
          conditionFields?.forEach(
            (f) =>
              (f.label = t(
                `feature.${featureName}.field.${f.field_name}.query_condition_field`,
                t(`feature.${featureName}.field.${f.field_name}`, f.label)
              ))
          );
          setAllFields(searchableFields);
          setFilteredFields(conditionFields);
          setConditionFields(conditionFields);
          setDefaultQueryFields(searchableFields?.filter((column) => column?.is_default || column?.is_required));
        }
      });
  }, [featureName, auth0]);

  useEffect(() => {
    if (page === pageCount && count < Number(totalResults)) {
      setPageToRequest(pageToRequest + 1);
    }
  }, [page]);

  useEffect(() => {
    if (count) {
      setPageCount(count / pageSize - 1);
    }
  }, [count]);

  useEffect(() => {
    if (defaultQueryFields && allFields) {
      initializeDefaultValues(defaultQueryFields, allFields);
    }
  }, [defaultQueryFields, allFields, featureName]);

  useEffect(() => {
    if (conditionFields) {
      const result = conditionFields.filter((filter: QueryFieldSchema) => {
        const searchTextInLowerCase = searchFieldQuery.toLowerCase();
        return filter.label?.toLowerCase().includes(searchTextInLowerCase);
      });
      setFilteredFields(result);
    }
  }, [searchFieldQuery]);

  useEffect(() => {
    if (allFields && query && userPermissions) {
      setQueryResultColumns(buildTableColumns(query, allFields, FeatureName[featureNameKey], userPermissions));
    }
  }, [allFields, query, userPermissions]);

  useEffect(() => {
    if (id) {
      getQueryDetail();
      setExpanded(false);
    } else {
      setQuery(null);
      setQueryResult([]);
      setTotalResults(null);
      setCount(0);
      setExpanded(true);
    }
  }, [id, featureId]);

  useEffect(() => {
    if (fields.length > 5) {
      const height = (fields.length - 5) * 100;
      setListHeight(400 + height);
    }
  }, [fields]);
  useEffect(() => {
    if (id && query && userPermissions) {
      handleRunQuery(false);
    }
  }, [id, query, userPermissions]);

  function initializePaginationState(response: any) {
    setQueryResult(response?.results);
    setTotalResults(response?.count_total);
    setCount(response?.count);
    setTrueTime(response?.true_time);
    setPageToRequest(1);
    setPage(0);
  }

  function getQueryData(): Query {
    const formStateValues = $state.getValue().values;
    const valuesFromReactHook = getValues();

    if (valuesFromReactHook.query_conditions) {
      valuesFromReactHook.query_conditions.forEach((condition: Condition) => {
        condition['values'] = condition['values'] === undefined ? [] : condition['values'];
      });
    }

    const queryData = {
      ...pick(!isEmpty(formStateValues) ? formStateValues : query, [
        order,
        orderBy,
        OwnershipFields.OPERATION,
        OwnershipFields.OPERATION_MODALITY,
        OwnershipFields.OWNERSHIP_TYPE,
        OwnershipFields.TEAM_OWNERSHIP,
        OwnershipFields.USER_OWNERSHIP,
      ]),
      ...pick(valuesFromReactHook, [queryCondition, queryFields]),
    };
    queryData.query_conditions[0].sequence_operator = LogicOperator.AND;
    return queryData;
  }

  function hasValidQueryOrderValues(order: string, orderBy: string): boolean {
    return !isEmpty(order) && !isEmpty(orderBy);
  }

  function handleRunQuery(pagination: boolean): void {
    if (!pagination) {
      setIsLoadingResults(true);
    }
    const queryData = getQueryData();
    const validQueryOrderValues = hasValidQueryOrderValues(queryData[order], queryData[orderBy]);
    if (validQueryOrderValues) {
      const pageToRequestParam = pagination ? pageToRequest : 1;
      const params = {
        action: 'run',
        feature: FeatureName[featureNameKey],
        page: pageToRequestParam,
      };

      if (pagination && trueTime) {
        params['true_time'] = trueTime;
      }
      runQuery({
        data: {...queryData, feature_name: featureId},
        params,
      }).then((response: any) => {
        const paginationResponse: PaginatedResponse<any> = response?.data;
        if (allFields) {
          setQueryResultColumns(
            buildTableColumns(queryData, allFields, FeatureName[featureNameKey], userPermissions ?? [])
          );
        }

        if (!pagination) {
          initializePaginationState(paginationResponse);
        } else {
          setQueryResult([...queryResult, ...(paginationResponse?.results || [])]);
          setCount(count + paginationResponse?.count);
          setTrueTime(paginationResponse?.true_time);
        }
        setIsLoadingResults(false);
      });
    } else {
      NotificationService.getInstance().sendNotification(t('shared.missing-field'), NotificationType.ERROR);
    }
  }

  function parseData(data: any, valuesFromReactHook: Query): Object {
    const modifiedData = difference(data, valuesFromReactHook);
    if ((modifiedData['order'] && !modifiedData['order_by']) || (!modifiedData['order'] && modifiedData['order_by'])) {
      modifiedData['order'] = data['order'];
      modifiedData['order_by'] = data['order_by'];
    }
    return modifiedData;
  }

  function onSubmit(data: any): void {
    const valuesFromReactHook = getValues();
    if (id) {
      // if update, prepare values for PATCH
      data = parseData(data, valuesFromReactHook);
    }
    const newDataFormBuilder = omit(data, [
      'query_conditions',
      'query_fields',
      'created_by',
      'conditions',
      'query_id',
      'last_updated_at',
    ]);

    let queryData = {
      ...pick(valuesFromReactHook, [
        queryCondition,
        queryFields,
        OwnershipFields.OPERATION,
        OwnershipFields.OPERATION_MODALITY,
        OwnershipFields.OWNERSHIP_TYPE,
        OwnershipFields.TEAM_OWNERSHIP,
        OwnershipFields.USER_OWNERSHIP,
      ]),
      ...newDataFormBuilder,
    };
    const params = {
      action: 'save',
    };
    if (id) {
      patchQuery({data: queryData, params}).then((response: any) => {
        setQuery(response?.data?.query);
        initializePaginationState(response?.data);
        if (response?.status === 200) {
          NotificationService.getInstance().sendNotification(
            t('shared.successfully-updated-msg'),
            NotificationType.SUCCESS
          );
        }
      });
    } else {
      postQuery({data: {...queryData, status: 'active', feature_name: featureId}, params}).then((response: any) => {
        if (response?.status === 201) {
          NotificationService.getInstance().sendNotification(
            t('shared.successfully-created-msg'),
            NotificationType.SUCCESS
          );

          navigate(`/queries/${featureName}/${response?.data?.['id']}`);
        }
      });
    }
  }
  useEffect(() => {
    if (deleteQueryResponse?.status === 200) {
      NotificationService.getInstance().sendNotification(deleteQueryResponse?.data?.message, NotificationType.SUCCESS);
      navigate(`/queries/${featureName}`);
    }
  }, [deleteQueryResponse]);
  function handleDeactivateQuery() {
    if (id) {
      deleteQueryData();
    }
  }

  function handleExportQuery(dataToExport: QueryToExport): void {
    const url = dataToExport?.id ? `${FeatureName.QUERIES}/${dataToExport?.id}` : FeatureName.QUERIES;
    const {user} = auth0;
    exportQuery({
      data: {...dataToExport.query, feature_name: featureId},
      url,
      params: {
        action: 'export',
        emails: getUserEmail(user),
      },
    }).then((response: any) => {
      NotificationService.getInstance().sendNotification(response?.data?.message, NotificationType.SUCCESS);
    });
  }

  function handleExportSavedQuery(id: string): void {
    handleExportQuery({id});
  }

  function handleSnapshotExportQuery(): void {
    const queryData = getQueryData();
    const validQueryOrderValues = hasValidQueryOrderValues(queryData[order], queryData[orderBy]);
    if (validQueryOrderValues) {
      handleExportQuery({query: queryData});
    } else {
      NotificationService.getInstance().sendNotification(t('shared.missing-field'), NotificationType.ERROR);
    }
  }

  useEffect(() => {
    if (pageToRequest > 1) {
      handleRunQuery(true);
    }
  }, [pageToRequest]);

  useEffect(() => {
    const isConditionValid: boolean = isQueryLastConditionValid(formState, queryConditions);
    const isFormValid = isConditionValid ? false : !isValid;
    setIsFormValid(isFormValid);
  }, [queryConditions, watchConditions, formState, isValid]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        {isValidId ? (
          <FormProvider {...methods}>
            {allFields && (
              <QueryContextProvider defaultValue={conditionFields}>
                <Grid container spacing={3}>
                  <PageStickyHeader>
                    <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
                      <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                        <PageHeadline>
                          {t('query.headline')} - {featureName} {query?.name}
                        </PageHeadline>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <PageTopActions>
                          {id && (
                            <AccessControl
                              userPermissions={userPermissions}
                              allowedPermissions={[UserPermissions.VIEW_EXPORT]}
                            >
                              <div>
                                <Tooltip title={t('query.actions.tooltip-export')} placement="top-start">
                                  <Button
                                    {...{[TestAttributes.BUTTON_NAME]: 'export-query-btn'}}
                                    id={'export-query-btn'}
                                    onClick={() => {
                                      handleExportSavedQuery(id);
                                    }}
                                    fullWidth
                                  >
                                    {t('shared.export')}
                                  </Button>
                                </Tooltip>
                              </div>
                            </AccessControl>
                          )}
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[UserPermissions.VIEW_EXPORT]}
                          >
                            <Tooltip title={t('query.actions.tooltip-snapshot-export')} placement="top-start">
                              <Button
                                {...{[TestAttributes.BUTTON_NAME]: 'snapshot-export-btn'}}
                                id={'snapshot-export-btn'}
                                disabled={isFormValid || !(fields.length > 0)}
                                color="secondary"
                                onClick={() => {
                                  handleSnapshotExportQuery();
                                }}
                              >
                                {t('query.actions.snapshot-export')}
                              </Button>
                            </Tooltip>
                          </AccessControl>
                          {id && (
                            <>
                              <AccessControl
                                userPermissions={userPermissions}
                                allowedPermissions={[UserPermissions.DEACTIVATE_FEATURE_ITEM]}
                              >
                                <ConfirmationDialog
                                  message={t('query.actions.deactivate-message')}
                                  headline={t('query.actions.deactivate')}
                                  isDialogOpen={isDeactivatedModalOpen}
                                  handleClose={() => setIsDeactivatedModalOpen(false)}
                                >
                                  <Button
                                    onClick={() => {
                                      handleDeactivateQuery();
                                      setIsDeactivatedModalOpen(false);
                                    }}
                                    id="confirmation-dialog-action-btn"
                                    {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-action-btn'}}
                                  >
                                    {t('query.actions.deactivate')}
                                  </Button>
                                </ConfirmationDialog>
                                <Tooltip title={t('query.actions.tooltip-delete')} placement="top-start">
                                  <Button
                                    {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-btn'}}
                                    id={'confirmation-dialog-btn'}
                                    color="secondary"
                                    onClick={() => setIsDeactivatedModalOpen(true)}
                                  >
                                    {t('query.actions.deactivate')}
                                  </Button>
                                </Tooltip>
                              </AccessControl>
                            </>
                          )}
                          <div>
                            <Tooltip title={t('shared.tooltip-undo')} placement="top-start">
                              <Button
                                {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-btn'}}
                                id={'confirmation-dialog-btn'}
                                color="secondary"
                                onClick={() => setIsConfirmationDialogOpen(true)}
                                fullWidth
                              >
                                {t('shared.clear')}
                              </Button>
                            </Tooltip>
                          </div>
                          <div>
                            <Tooltip title={t('query.actions.tooltip-run')} placement="top-start">
                              <Button
                                color="secondary"
                                {...{[TestAttributes.BUTTON_NAME]: 'run-query-btn'}}
                                id={'run-query-btn'}
                                disabled={isFormValid || !(fields.length > 0)}
                                onClick={() => {
                                  handleRunQuery(false);
                                  reset(getValues());
                                }}
                                fullWidth
                              >
                                {t('query.actions.run')}
                              </Button>
                            </Tooltip>
                          </div>
                          <AccessControl
                            userPermissions={userPermissions}
                            allowedPermissions={[id ? UserPermissions.MODIFY : UserPermissions.CREATE]}
                          >
                            <Tooltip
                              title={id ? t('query.actions.tooltip-update') : t('query.actions.tooltip-save')}
                              placement="top-start"
                            >
                              <Button
                                id={'save-query-btn'}
                                {...{[TestAttributes.BUTTON_NAME]: 'save-query-btn'}}
                                onClick={() => formAPI.submit()}
                                disabled={isFormValid || !(fields.length > 0)}
                              >
                                {id ? t('shared.update') : t('shared.save')}
                              </Button>
                            </Tooltip>
                          </AccessControl>
                        </PageTopActions>
                      </Grid>
                    </Grid>
                  </PageStickyHeader>

                  <Grid item xs={12} md={12}>
                    <>
                      <FormBuilder
                        formId={queryFormFeatureName}
                        api={formAPI}
                        onSubmit={onSubmit}
                        initialValues={query}
                        config={config}
                        onRejectSubmit={onRejectSubmit}
                      />
                      <QueryTotalResult totalResults={totalResults} isDirty={isDirty} />
                      <Grid item xs={12}>
                        <Divider
                          sx={{
                            marginBottom: (theme) => theme.spacing(2),
                            marginTop: (theme) => theme.spacing(2),
                          }}
                        />
                      </Grid>
                    </>
                  </Grid>

                  <Grid item container xs={12} spacing={3}>
                    <Grid item xs={12}>
                      <QueryFieldsSortable fields={allFields} />
                    </Grid>
                    <Grid item xs={12}>
                      <Accordion expanded={expanded}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          onClick={() => {
                            setExpanded(!expanded);
                          }}
                        >
                          <Typography variant="h4">{t('query.conditions')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={3}>
                            <Grid container item xs={12} lg={3} spacing={3} alignContent={'flex-start'}>
                              <Grid item xs={12}>
                                <InputLabel htmlFor="query-search-field-input">{t('query.filter')}</InputLabel>
                                <TextField
                                  fullWidth
                                  id="query-search-field-input"
                                  placeholder={t('query.filter')}
                                  onChange={(event) => setSearchFieldQuery(event.target.value)}
                                  value={searchFieldQuery}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                {filteredFields.length > 0 && (
                                  <VirtualFieldList
                                    height={listHeight}
                                    fields={filteredFields}
                                    handleOnClick={(field: QueryFieldSchema) => {
                                      QueryService.getInstance().handleOnFieldClick(
                                        field,
                                        fields,
                                        defaultConditionFieldName,
                                        insert,
                                        append
                                      );

                                      setListHeight(listHeight + (fields.length > 5 ? 100 : 0));
                                    }}
                                    disabled={
                                      formState.isDirty && isFormValid && getValues('query_conditions')?.length !== 0
                                    }
                                  ></VirtualFieldList>
                                )}
                              </Grid>
                            </Grid>

                            <Grid item xs={12} lg={9}>
                              {fields.map((conditionField: Condition, index: number) => {
                                let isDefaultStatusField = true;
                                if (featuresWithDefaultConditions.includes(featureId as FeatureName)) {
                                  isDefaultStatusField =
                                    conditionField?.field_name !== defaultConditionFieldName ||
                                    (conditionField?.field_name === defaultConditionFieldName &&
                                      index !== fields.length - 1);
                                }

                                return (
                                  <QueryCondition
                                    key={'query-conditions-' + conditionField.id}
                                    conditionField={conditionField}
                                    conditionIndex={index}
                                  >
                                    <Grid item alignSelf={'flex-end'}>
                                      {isDefaultStatusField && (
                                        <IconButton
                                          {...{[TestAttributes.BUTTON_NAME]: `remove-condition-${index}-btn`}}
                                          id={`remove-condition-${index}-btn`}
                                          color="primary"
                                          aria-label="remove condition"
                                          component="button"
                                          onClick={() => {
                                            remove(index);
                                            setListHeight(listHeight - (fields.length > 6 ? 100 : 0));
                                          }}
                                          size="large"
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      )}
                                    </Grid>

                                    <Grid item container flexWrap={'nowrap'} alignSelf={'flex-end'}>
                                      {index !== 0 && isDefaultStatusField && (
                                        <IconButton
                                          {...{[TestAttributes.BUTTON_NAME]: `swap-up-condition-${index}-btn`}}
                                          id={`swap-up-condition-${index}-btn`}
                                          color={'secondary'}
                                          aria-label="swap up condition"
                                          component="button"
                                          onClick={() => swap(index, index - 1)}
                                          size="large"
                                        >
                                          <ArrowUpwardRoundedIcon />
                                        </IconButton>
                                      )}
                                      {index !== lastCondition && isDefaultStatusField && (
                                        <IconButton
                                          {...{[TestAttributes.BUTTON_NAME]: `swap-down-condition-${index}-btn`}}
                                          id={`swap-down-condition-${index}-btn`}
                                          color={'secondary'}
                                          aria-label="swap down condition"
                                          component="button"
                                          onClick={() => swap(index, index + 1)}
                                          size="large"
                                        >
                                          <ArrowDownwardRoundedIcon />
                                        </IconButton>
                                      )}
                                    </Grid>
                                  </QueryCondition>
                                );
                              })}
                              {fields.length === 0 && (
                                <Box display={'flex'} justifyContent={'center'}>
                                  <Typography variant={'h4'}>{t('query.empty-query-message')}</Typography>
                                </Box>
                              )}
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  {!isLoadingResults ? (
                    <>
                      <QueryTotalResult totalResults={totalResults} isDirty={isDirty} />
                      <Grid
                        item
                        xs={12}
                        sx={{height: '500px', marginBottom: '70px'}}
                        {...{[TestAttributes.TABLE_NAME]: 'table-component'}}
                      >
                        <DataGridCustom
                          tableColumns={queryResultColumns}
                          rows={
                            queryResult?.map((item: QueryDetailLead, index: number) => {
                              return {
                                actions: item.lead_id,
                                ...item,
                                id: index,
                              };
                            }) || []
                          }
                          userPermissions={userPermissions}
                          page={page}
                          pageSize={pageSize}
                          rowsPerPageOptions={[pageSize]}
                          onPageChange={(newPage: any) => setPage(newPage)}
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        item
                        xs={6}
                        sx={{marginTop: (theme) => theme.spacing(4)}}
                        {...{[TestAttributes.SECTION_NAME]: 'section-zero-results'}}
                      >
                        <CircularProgress color="primary" />
                      </Grid>
                    </>
                  )}
                </Grid>
              </QueryContextProvider>
            )}
          </FormProvider>
        ) : (
          <FeatureNotFound />
        )}
        <ConfirmationDialog
          message={t('shared.clear-form-modal-content')}
          headline={t('shared.clear-form-modal-headline')}
          isDialogOpen={isConfirmationDialogOpen}
          handleClose={() => setIsConfirmationDialogOpen(false)}
        >
          <Button
            onClick={() => {
              formAPI.reset();
              setIsConfirmationDialogOpen(false);
            }}
            {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-action-btn'}}
            id="confirmation-dialog-action-btn"
          >
            {t('shared.accept')}
          </Button>
        </ConfirmationDialog>
      </Container>
    </Box>
  );
}

export default QueryBuilder;
