import React from 'react';
import {Chip} from '@mui/material';
import {Box} from '@mui/material';
import {SortableContext, useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

export function ValueContainer(props: any) {
  const items = props?.getValue()?.map((i: any) => i.value);
  const {theme, cx, ...otherProps} = props;

  return (
    <SortableContext items={items}>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          padding: '4px',
          overflow: 'auto',
          flexWrap: 'wrap',
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary.contrastText,
        }}
        {...otherProps}
      ></Box>
    </SortableContext>
  );
}

export function MultiValue(props: any) {
  const {children, removeProps} = props;

  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({
    id: props.data.value,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const onMouseDown = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onDelete = (event: any) => {
    if (removeProps && removeProps.onClick && removeProps.onMouseDown) {
      removeProps.onClick(event);
      removeProps.onMouseDown(event);
    }
  };
  return (
    <div onMouseDown={onMouseDown} {...props.innerProps} {...attributes} {...listeners} style={style} ref={setNodeRef}>
      <Chip
        label={children}
        sx={{
          fontWeight: 'lighter',
          margin: '2px',
          cursor: '-webkit-grab',
        }}
        onDelete={props.data.is_required ? undefined : onDelete}
      />
    </div>
  );
}
