import {FormFieldType} from '../form/logic/FormSchema';
import {ConditionOperator, LogicOperator} from '../pages/query/ConditionOperator';

export interface Condition {
  id?: string;
  field_id: string;
  field_type: FormFieldType;
  data_type: FormDataType;
  field_name: string;
  condition_type: ConditionOperator;
  values: Array<QueryConditionValue>;
  sequence_operator?: LogicOperator;
}
export interface Query {
  query_fields?: Array<string>;
  show_inactive?: boolean;
  query_conditions: Array<Condition>;
}
export interface QueryCondition {
  field_name: string;
  field_type: FormFieldType;
  condition_type: ConditionOperator;
  values: Array<QueryConditionValue>;
}
export interface QueryConditionValue {
  value: string | number;
}

export interface QueryFormSchemaResponseWrapper {
  sections?: QueryFormSectionSchema[];
  query_display_fields: QueryFieldSchema[];
  query_condition_fields: QueryFieldSchema[];
  // cta: string;
  // columns: Array<any>; // FIXME when rendering the query list
  // default_columns: Array<any>;
}

export interface QueryFormSectionSchema {
  section_id: string;
  section_name?: string;
  index?: number;
  sections?: QueryFormSectionSchema[];
  fields?: QueryFieldSchema[];
}

export interface QueryFieldSchema {
  field_id: string;
  field_name: string;
  field_type: FormFieldType;
  data_type: FormDataType;
  searchable?: boolean;
  label?: string;
  label_search?: string;
  select_options?: any[]; // FIXME what am I?
  is_default?: boolean;
  is_required?: boolean;
}

export enum FormDataType {
  TEXT = 'text',
  LIST = 'list',
  NUMBER = 'number',
  DATE = 'date',
  PHONE = 'phone',
  TIMESTAMP = 'timestamp',
  BOOLEAN = 'boolean',
  SELECT = 'select',
}

export interface QueryListItemCreatedBy {
  username: string;
}

export interface QueryListItem {
  query_id: string;
  name: string;
  created_at: Date;
  created_by: QueryListItemCreatedBy;
  last_updated_by: QueryListItemCreatedBy;
  public: boolean;
  status: string;
  order_by: string;
}

export interface QueryDetail {
  count: number;
  count_total: number;
  query: QuerySaved;
  query_id: string;
  true_time: string;
  results: Array<QueryDetailLead>;
}
export interface QuerySaved {
  query_fields?: Array<string>;
  show_inactive?: boolean;
  query_conditions?: Array<Condition>;
  created_at?: string;
  created_by?: {username: string; user_id: string};
  name?: string;
  order?: string;
  order_by?: string;
  public?: boolean;
  query_id?: string;
  random?: boolean;
  status?: string;
}
export interface QueryToCopy {
  query_id: string;
  name: string;
}

export interface QueryToExport {
  query?: Query;
  id?: string;
}

export interface QueryDetailLead {
  created_at: string;
  created_by: {username: string; user_id: string};
  email: string;
  first_name: string;
  last_name: string;
  lead_id: string;
  lead_id_actions: string;
  lead_type: Array<string>;
  primary_phone: string;
}

export enum QueryMode {
  PUBLIC = 'public',
  PRIVATE = 'private',
  AUTO_ACTION = 'auto action',
}
