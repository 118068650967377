import {Link, matchPath, useLocation} from 'react-router-dom';
import {Button, ListItemButton, ListItemIcon, ListItemText, Tooltip, useTheme} from '@mui/material';
import React, {MouseEventHandler, PropsWithChildren, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ConfirmationDialog from './dialogs/ConfirmationDialog';
import {MaterialUiIcons} from './MaterialUiIcons';
import SideNavMenuIcon from './SideNavMenuIcon';
import {paletteModeDark} from '../context/LightDarkThemeProvider';

interface NavItemProps {
  href?: string;
  icon?: string;
  title: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  isCollapse: boolean;
  hasChildren: boolean;
  actionMessage?: string;
  tooltip: string;
}

export function NavItem({
  href,
  icon,
  title,
  onClick,
  children,
  isCollapse,
  hasChildren,
  actionMessage,
  tooltip,
}: PropsWithChildren<NavItemProps>) {
  const {t} = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const titleKey = t(title) ?? '';
  const active = href
    ? !!matchPath(
        {
          path: href,
          end: true,
        },
        location.pathname
      )
    : false;

  const isUrlExternal = (href?: string) => {
    const searchProtocol = href?.startsWith('//');
    let isExternal = false;
    if (searchProtocol) {
      isExternal = true;
    }
    return isExternal;
  };

  const handleExternalUrl = (event: React.MouseEvent, href?: string) => {
    const urlExternal = isUrlExternal(href);

    if (urlExternal || titleKey.toLowerCase() === 'sparta tools') {
      event.preventDefault();
    }

    if (urlExternal) {
      if (title.includes('phone')) {
        setIsConfirmationDialogOpen(true);
      } else {
        window.open(href, '_blank');
      }
    }
  };

  const iconName = icon && MaterialUiIcons[icon] ? icon : title.split('.')?.pop()?.toLowerCase() || '';
  const isDark = theme.palette.mode === paletteModeDark;
  const sideNavMenuFontColor = 'inherit';
  const sideNavMenuFontWeight = '300';
  const buttonHoverBGColor = isDark ? 'rgba(219,230,250,0.5)' : theme.palette.secondary.main;

  return (
    <>
      <Tooltip title={t(tooltip)} placement="right">
        <Link
          to={href && !isUrlExternal(href) ? href : ''}
          onClick={(event: React.MouseEvent) => handleExternalUrl(event, href)}
          id={'sidenav-item-' + title.split('.').pop()?.toLowerCase()}
        >
          <ListItemButton
            onClick={onClick}
            sx={{
              paddingLeft: theme.spacing(0),
              backgroundColor: active ? buttonHoverBGColor : 'inherit',
              borderRadius: '2px',
              '&:hover': {
                backgroundColor: buttonHoverBGColor,
              },
              paddingBlock: '8px',
            }}
          >
            <ListItemIcon sx={{minWidth: 'fit-content'}}>
              <SideNavMenuIcon
                color={theme.palette.secondary.light}
                hasChildren={hasChildren}
                isCollapsed={isCollapse}
                iconName={iconName}
              />
            </ListItemIcon>
            <ListItemText
              sx={{marginBlock: '0'}}
              primary={titleKey}
              primaryTypographyProps={{
                variant: 'subtitle2',
                style: {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: sideNavMenuFontColor,
                  fontWeight: sideNavMenuFontWeight,
                },
              }}
            />
            {children && children}
          </ListItemButton>
        </Link>
      </Tooltip>
      {actionMessage && (
        <ConfirmationDialog
          message={t(actionMessage)}
          headline={titleKey}
          isDialogOpen={isConfirmationDialogOpen}
          handleClose={() => {
            setIsConfirmationDialogOpen(false);
          }}
        >
          <Button
            onClick={() => {
              window.open(href, '_blank');
              setIsConfirmationDialogOpen(false);
            }}
            id="confirmation-dialog-action-btn"
          >
            {t('shared.accept')}
          </Button>
        </ConfirmationDialog>
      )}
    </>
  );
}
